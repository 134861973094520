import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
} from '@angular/core';
import { catchError, Subject, takeUntil, throwError } from 'rxjs';
import { AlertService } from '@src/core/services';

import { RegistrationFormUI } from './types';
import { RegistrationService } from './services/registration.service';

@Component({
  selector: 'app-registration',
  templateUrl: './registration.component.html',
  styleUrls: ['./registration.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RegistrationComponent implements OnDestroy {
  @Input() associationId?: string;

  @Output() saved: EventEmitter<string> = new EventEmitter<string>();

  loading = false;

  private destroyed$$: Subject<void> = new Subject<void>();

  constructor(
    private readonly registrationService: RegistrationService,
    private readonly alertService: AlertService,
    readonly cdr: ChangeDetectorRef,
  ) {}

  ngOnDestroy(): void {
    this.destroyed$$.next();
    this.destroyed$$.complete();
  }

  saveRegistrationForm(data: RegistrationFormUI): void {
    this.loading = true;

    this.registrationService
      .sendRegistrationForm(data)
      .pipe(
        takeUntil(this.destroyed$$),
        catchError(err => {
          this.alertService.error(err);
          this.loading = false;
          this.cdr.markForCheck();

          return throwError(() => err);
        }),
      )
      .subscribe(res => {
        const newUserId = res.id;

        this.loading = false;
        this.cdr.markForCheck();
        this.saved.emit(newUserId);
      });
  }
}

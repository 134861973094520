import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export abstract class BaseSearchService<FilterType, ReturnType> {
  filter$: BehaviorSubject<FilterType | null> = new BehaviorSubject<FilterType | null>(null);
  pagination$: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  abstract search(filter: FilterType, pagination: number): Observable<ReturnType>;
}

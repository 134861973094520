import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { TuiAvatarModule, TuiBadgedContentModule } from '@taiga-ui/kit';
import {
  TuiDataListModule,
  TuiHintModule,
  TuiHostedDropdownModule,
  TuiModeModule,
  TuiScrollbarModule,
  TuiTooltipModule,
  TuiDropdownModule,
} from '@taiga-ui/core';
import { NgxPermissionsModule } from 'ngx-permissions';
import { ComponentsModule } from '@src/app/components';
import { LogoModule } from '@src/app/modules/branding/modules';
import { SharedModule } from '@src/app/shared';
import { IconModule } from '@src/app/modules/icon';
import { ThemeSwitcherModule } from '@src/app/modules/theme-switcher';
import { LanguageSwitcherModule } from '@src/app/modules/language-switcher';

import { NavigationComponent } from './navigation.component';
import { NavigationDesktopComponent } from './navigation-desktop/navigation-desktop.component';
import { NavigationMobileComponent } from './navigation-mobile/navigation-mobile.component';
import { NavigationListComponent } from './navigation-list/navigation-list.component';
import { AssociationMenuComponent } from './association-menu/association-menu.component';
import { NavigationLogoComponent } from './navigation-logo/navigation-logo.component';
import { NavigationMessageComponent } from './navigation-message/navigation-message.component';

@NgModule({
  declarations: [
    NavigationComponent,
    NavigationDesktopComponent,
    NavigationMobileComponent,
    NavigationListComponent,
    AssociationMenuComponent,
    NavigationLogoComponent,
    NavigationMessageComponent,
  ],
  imports: [
    CommonModule,
    ComponentsModule,
    RouterModule,
    IconModule,
    LogoModule,
    NgxPermissionsModule,
    TuiAvatarModule,
    TuiBadgedContentModule,
    TuiDataListModule,
    TuiDropdownModule,
    TuiHintModule,
    TuiHostedDropdownModule,
    TuiModeModule,
    TuiScrollbarModule,
    TuiTooltipModule,
    SharedModule,
    ThemeSwitcherModule,
    LanguageSwitcherModule,
  ],
  exports: [NavigationComponent, NavigationListComponent],
})
export class NavigationModule {}

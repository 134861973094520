/**
 * Unions API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { UserProfileWithJobTitles } from './userProfileWithJobTitles';


/**
 * Тело тандартного ответа апи.
 */
export interface UserProfileWithJobTitlesIEnumerableApiResponse { 
    /**
     * Код ответа (http result).
     */
    responseCode?: number;
    /**
     * Статус ответа: TRUE - без ошибок; иначе FALSE.
     */
    isSuccess?: boolean;
    /**
     * Номер ошибки.
     */
    errorNumber?: string;
    /**
     * Сообщеине.
     */
    message?: string;
    /**
     * Кол-во основных элементов.
     */
    count?: number;
    /**
     * Ответ из метода.
     */
    result?: Array<UserProfileWithJobTitles>;
}


<div class="policy-page">
  <div class="policy-page__title">{{ 'components.policy.labels.title' | translate }}</div>

  <div class="policy-page__links">
    <!-- Политика конфиденциальности ассоциации -->
    <app-download-link
      *ngIf="privacyPolicyLink"
      class="download-link download-link_association"
      [fileLink]="privacyPolicyLink"
      [isLocalFile]="false"
    >
      {{ 'components.policy.buttons.association' | translate : { value: associationShortName ?? '' } }}
    </app-download-link>

    <!-- Политика конфиденциальности UNIONS -->
    <app-download-link class="download-link download-link_unions" fileLink="assets/docs/unions-policy.pdf">
      {{ 'components.policy.buttons.unions' | translate }}
    </app-download-link>

    <!-- Политика конфиденциальности Яндекс (для SmartCaptcha) -->
    <app-download-link
      *ngIf="enterType === EnterType.Email && !isCordova"
      class="download-link download-link_yandex"
      fileLink="https://yandex.ru/legal/smartcaptcha_notice/"
      [isLocalFile]="false"
    >
      {{ 'components.policy.buttons.yandex' | translate }}
    </app-download-link>

    <!-- Согласие на получение рекламы -->
    <app-download-link
      class="download-link download-link_advertising-receive"
      fileLink="assets/docs/Согласие на получение рекламы.pdf"
    >
      {{ 'components.policy.buttons.advertisingReceive' | translate }}
    </app-download-link>
  </div>

  <button tuiButton class="policy-page__button-close" size="m" routerLink="/" (click)="closePage()">
    {{ 'common.buttons.close' | translate }}
  </button>
</div>

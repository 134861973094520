import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { takeUntil } from 'rxjs';
import { BreakpointObserver } from '@angular/cdk/layout';
import { TranslateService } from '@ngx-translate/core';
import { ContactsService, GetUserContactsForUnionResponseDto } from '@src/api';
import { ResizableBaseComponent } from '@src/app/components';
import { AuthUserService } from '@src/app/modules/auth';
import { AlertService, BreakpointObserverHelperService } from '@src/core/services';

@Component({
  selector: 'app-standard-user-info-fields',
  templateUrl: './standard-user-info-fields.component.html',
  styleUrls: ['./standard-user-info-fields.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StandardUserInfoFieldsComponent extends ResizableBaseComponent {
  loading = false;
  fields: Array<GetUserContactsForUnionResponseDto> = [];

  constructor(
    readonly cdr: ChangeDetectorRef,
    readonly breakpointObserver: BreakpointObserver,
    readonly breakpointObserverHelperService: BreakpointObserverHelperService,
    private readonly contactsService: ContactsService,
    private readonly authUserService: AuthUserService,
    private readonly alertService: AlertService,
    private readonly translateService: TranslateService,
  ) {
    super(cdr, breakpointObserver, breakpointObserverHelperService);
  }

  get parentOrganisationId() {
    return this.authUserService.user?.parentOrganisationId;
  }

  ngOnInit(): void {
    super.ngOnInit();

    if (this.parentOrganisationId) {
      this.loading = true;
      this.contactsService
        .getUserContactsForUnion(this.parentOrganisationId)
        .pipe(takeUntil(this.destroyed$$))
        .subscribe(fields => {
          this.fields = fields;
          this.loading = false;

          this.cdr.markForCheck();
        });
    }
    this.cdr.markForCheck();
  }

  onSave() {
    this.loading = true;
    this.contactsService
      .addOrUpdateUserContactsForUnion(this.fields)
      .pipe(takeUntil(this.destroyed$$))
      .subscribe(() => {
        this.loading = false;
        this.alertService.success(this.translateService.instant('common.alerts.successes.saveData'));

        this.cdr.markForCheck();
      });
    this.cdr.markForCheck();
  }
}

import { Injectable, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { catchError, throwError } from 'rxjs';
import mime from 'mime';
import { TranslateService } from '@ngx-translate/core';
import { logger } from '@src/utils';
import { AlertService } from '@src/core/services';
import { AndroidPermissions, ICordovaPluginAndroidPermissions } from '@src/models';
import { APP_CONFIG } from '@src/core';
import { AuthUserService } from '@src/app/modules/auth';
import { UserService } from '@src/api';
import { environment } from '@src/environments/environment';

let self: any;

interface EventForCalendar {
  title?: string;
  eventLocation?: string;
  notes?: string;
  startDate?: Date | null;
  endDate?: Date | null;
  onlineLink?: string;
}

type GetUpdateAvailabilityResult =
  | 'UPDATE_AVAILABLE' // Когда этот метод возвращает значение UPDATE_AVAILABLE, ваше приложение готово использовать следующие методы для запроса обновления у пользователя.
  | 'UPDATE_NOT_AVAILABLE'
  | 'DEVELOPER_TRIGGERED_UPDATE_IN_PROGRESS'
  | 'UNKNOWN';

type UpdateFlexibleResult =
  | 'UPDATE_NOT_AVAILABLE' // В Play Store нет доступных обновлений.
  | 'DEVELOPER_TRIGGERED_UPDATE_IN_PROGRESS' // Выполняется либо гибкое, либо немедленное обновление.
  | 'UPDATE_PROMPT' // Пользователю будет предложено открыть диалоговое окно Play Store, чтобы загрузить гибкое обновление или проигнорировать его.
  | 'RESULT_OK' // Пользователь согласился загрузить гибкое обновление.
  | 'RESULT_CANCELED' // Пользователь отклонил диалоговое окно гибкого обновления или обновление было прервано во время выполнения.
  | 'RESULT_IN_APP_UPDATE_FAILED' // Что-то пошло не так с ответом в диалоговом окне обновления.
  | 'ACTIVITY_RESULT_UNKNOWN' // Неизвестный код результата, возвращаемый диалоговым окном.
  | 'DOWNLOADING' // В данный момент в фоновом режиме загружается обновление.
  | 'DOWNLOADED'; // Обновление было загружено, и на экране появилась панель быстрого доступа с кнопкой перезапуска.

@Injectable({
  providedIn: 'root',
})
export class CordovaService {
  cordova?: any;
  isCordova?: boolean;
  permissionsPlugin?: ICordovaPluginAndroidPermissions;
  requiredAndroidPermissions: AndroidPermissions[] = [
    AndroidPermissions.WRITE_EXTERNAL_STORAGE,
    AndroidPermissions.READ_EXTERNAL_STORAGE,
    AndroidPermissions.RECORD_AUDIO,
    AndroidPermissions.MODIFY_AUDIO_SETTINGS,
    AndroidPermissions.CAMERA,
    AndroidPermissions.READ_CALENDAR,
    AndroidPermissions.WRITE_CALENDAR,
    AndroidPermissions.POST_NOTIFICATIONS,
    AndroidPermissions.READ_MEDIA_IMAGES,
    AndroidPermissions.READ_MEDIA_VIDEO,
    AndroidPermissions.READ_MEDIA_AUDIO,
  ];
  fileOpener2Plugin?: any;
  calendarPlugin?: any;
  inAppUpdatePlugin?: any;
  universalLinksPlugin?: any;
  firebasePlugin?: any;
  devicePlugin?: any;

  constructor(
    private readonly alertService: AlertService,
    private readonly translateService: TranslateService,
    private readonly router: Router,
    private readonly zone: NgZone,
    private readonly authUserService: AuthUserService,
    private readonly api: UserService,
  ) {
    this.init();
  }

  async init() {
    self = this;
    this.cordova = (window as any).cordova;
    this.isCordova = this.cordova !== undefined;

    this.fileOpener2Plugin = this.cordova?.plugins?.fileOpener2;
    this.calendarPlugin = (window as any).plugins?.calendar;
    this.devicePlugin = (window as any).device;

    this.permissionsPlugin = this.cordova?.plugins?.permissions;
    if (this.permissionsPlugin) {
      this.checkRequiredAndroidPermissions();
    }

    this.inAppUpdatePlugin = this.cordova?.plugins?.InAppUpdate;
    if (this.inAppUpdatePlugin) {
      this.checkUpdate();
    }

    this.universalLinksPlugin = (window as any).plugins?.universalLinks;
    if (this.universalLinksPlugin) {
      this.initUniversalLinks();
    }

    this.firebasePlugin = (window as any).FirebasePlugin;
    if (this.firebasePlugin) {
      this.checkIOSNotificationPermission(false);
    }
  }

  checkUpdate() {
    this.inAppUpdatePlugin?.getUpdateAvailability(
      (result: GetUpdateAvailabilityResult) => {
        logger('getUpdateAvailability', result);

        if (result === 'UPDATE_AVAILABLE') {
          this.inAppUpdatePlugin?.updateFlexible(
            (result: UpdateFlexibleResult) => {
              logger('updateFlexible', result);
            },
            (error: string) => {
              logger('updateFlexible - error', error);
            },
          );
        }
      },
      (error: string) => {
        logger('getUpdateAvailability error', error);
      },
    );
  }

  checkRequiredAndroidPermissions() {
    if (!this.permissionsPlugin) return;

    this.permissionsPlugin.requestPermissions(
      this.requiredAndroidPermissions,
      res => {
        logger('checkRequiredAndroidPermissions', res);
      },
      err => {
        logger('checkRequiredAndroidPermissions - error', err);
      },
    );
  }

  checkPermission(permission: AndroidPermissions): Promise<boolean> {
    return new Promise(resolve => {
      if (!this.permissionsPlugin) return resolve(false);

      this.permissionsPlugin.checkPermission(
        permission,
        res => {
          return resolve(res.hasPermission ?? false);
        },
        err => {
          logger('checkPermission - error', err);
          return resolve(false);
        },
      );
    });
  }

  requestPermission(permission: AndroidPermissions): Promise<boolean> {
    return new Promise(resolve => {
      if (!this.permissionsPlugin) return resolve(false);

      this.permissionsPlugin.requestPermission(
        permission,
        res => {
          return resolve(res.hasPermission ?? false);
        },
        err => {
          logger('requestPermission - error', err);
          return resolve(false);
        },
      );
    });
  }

  downloadFile(blob: Blob | string, fileName: string) {
    if (!blob || !fileName) return;

    // Описание всех папок для Android и iOS https://github.com/apache/cordova-plugin-file#file-system-layouts
    const fileDir = self.cordova.file.dataDirectory;

    (window as any).resolveLocalFileSystemURL(
      fileDir,
      function (fs: any) {
        logger(`$ file system opened directory ${fileDir}`);
        logger(`$ opening ${fileName}`);
        fs.getFile(
          fileName,
          { create: true, exclusive: false },
          function (fileEntry: any) {
            logger('getFile - ok!');
            self.writeFile(fileEntry, blob);
          },
          function (err: any) {
            logger('getFile - error', err);
          },
        );
      },
      function (err: any) {
        logger('$ resolveLocalFileSystemURL err', err);
      },
    );
  }

  writeFile(fileEntry: any, blob: Blob | string) {
    const fileName = fileEntry.name;
    const fileMIMEType = mime.getType(fileName);
    logger('fileMIMEType', fileMIMEType);

    fileEntry.createWriter(function (fileWriter: any) {
      fileWriter.onwriteend = function () {
        logger('fileWriter - ok!');
        self.alertService.success(
          `${self.translateService.instant('common.labels.file')} ${fileName} ${self.translateService.instant(
            'common.labels.uploaded',
          )}`,
        );
        self.openFile(fileEntry.nativeURL, fileMIMEType);
      };
      fileWriter.onerror = function (err: any) {
        logger('fileWriter - error', err);
      };
      fileWriter.write(blob);
    });
  }

  openURL(url: string, baseUrl: boolean = false) {
    this.cordova.InAppBrowser.open(baseUrl ? `${APP_CONFIG.baseUrl}/${url}` : url, '_system');
  }

  openFile(filePath: string, fileMIMEType: string) {
    if (!this.fileOpener2Plugin) {
      // TODO: localization console перевести
      logger('Ошибка! Плагин fileOpener2 для Cordova не подключен');
      return;
    }

    this.fileOpener2Plugin.open(filePath, fileMIMEType, {
      error: function (err: any) {
        logger('Error status: ' + err.status + ' - Error message: ' + err.message);
      },
      success: function () {
        logger('file opened successfully');
      },
    });
  }

  addEventToCalendar({
    title = '',
    eventLocation = '',
    notes = '',
    startDate = null,
    endDate = null,
    onlineLink = undefined,
  }: EventForCalendar) {
    const calOptions = this.calendarPlugin.getCalendarOptions();
    if (onlineLink) {
      calOptions.url = onlineLink;
    }

    this.calendarPlugin.createEventInteractivelyWithOptions(
      title,
      eventLocation,
      notes,
      startDate,
      endDate,
      calOptions,
      () => {
        this.alertService.success(this.translateService.instant('common.alerts.successes.addEventToCalendar'));
      },
      (error: any) => {
        this.alertService.error(this.translateService.instant('common.alerts.errors.addEventToCalendar'));
        logger(this.translateService.instant('common.alerts.errors.addEventToCalendar'), error);
      },
    );
  }

  initUniversalLinks() {
    if (!this.universalLinksPlugin) {
      // TODO: localization console перевести
      logger('Ошибка! Плагин universalLinks для Cordova не подключен');
      return;
    }

    this.universalLinksPlugin.subscribe(null, (eventData: any) => {
      this.zone.run(() => {
        this.authUserService.authUser$.subscribe(authUser => {
          if (authUser) {
            this.router
              .navigate([eventData.path], {
                queryParams: eventData.params,
              })
              .then();
          }
        });
      });
    });
  }

  checkIOSNotificationPermission(requested: boolean) {
    this.firebasePlugin.hasPermission(
      (hasPermission: boolean) => {
        logger('checkIOSNotificationPermission', hasPermission);
        if (hasPermission) {
          // Granted
          this.getPushToken();
        } else if (!requested) {
          // Request permission
          this.grantPermission();
        } else {
          // Denied
          logger('Разрешение на push-уведомления не предоставлено');
        }
      },
      (error: string) => {
        logger('hasPermission - error', error);
      },
    );
  }

  grantPermission() {
    if (!this.firebasePlugin) {
      // TODO: localization console перевести
      logger('Ошибка! Плагин firebasePlugin для Cordova не подключен');
      return;
    }

    // TODO: не работает
    this.firebasePlugin.grantPermission(
      (hasPermission: boolean) => {
        logger('grantPermission', hasPermission);
        if (hasPermission) {
          this.getPushToken();
        }
      },
      (error: string) => {
        logger('grantPermission - error', error);
      },
      (requestWithProvidesAppNotificationSettings: boolean) => {
        logger(
          'grantPermission - requestWithProvidesAppNotificationSettings',
          requestWithProvidesAppNotificationSettings,
        );
      },
    );
  }

  getPushToken() {
    if (!this.firebasePlugin) {
      // TODO: localization console перевести
      logger('Ошибка! Плагин firebasePlugin для Cordova не подключен');
      return;
    }

    this.firebasePlugin.getToken(
      (fcmToken: string) => {
        this.pushSubscribe();
        this.authUserService.authUser$.subscribe(authUser => {
          if (authUser) {
            if (!this.devicePlugin) {
              // TODO: localization console перевести
              logger('Ошибка! Плагин device для Cordova не подключен');
              return;
            }

            this.api
              .registerDeviceId({
                deviceId: fcmToken,
                osType: this.devicePlugin.platform,
                osVersion: this.devicePlugin.version,
                languageCode: this.translateService.currentLang,
                unionsVersion: environment.appVersion,
              })
              .pipe(
                catchError(err => {
                  logger('registerDeviceId - error', err);
                  return throwError(() => err);
                }),
              )
              .subscribe();
          }
        });
      },
      (error: string) => {
        logger('getToken - error', error);
      },
    );
  }

  pushSubscribe() {
    this.firebasePlugin?.onMessageReceived(
      (message: any) => {
        if (message.messageType === 'notification') {
          // Чтобы уведомление отображалось когда приложение на переднем плане, нужно добавить "notification_foreground": "true" в data
          // Если приложение закрыто, то уведомление отображается, но при нажатии переход по ссылке не работает
          // (причина - приложение не успевает загрузиться. добавил ожидание загрузки данных текущего пользователя)
          if (message.tap) {
            if (message.url) {
              this.zone.run(() => {
                this.authUserService.authUser$.subscribe(authUser => {
                  if (authUser) {
                    this.router.navigateByUrl(message.url).then();
                  }
                });
              });
            }
          }
        }
      },
      (error: any) => {
        logger('onMessageReceived - error', error);
      },
    );
  }
}

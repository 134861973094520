import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { DatePipe, formatDate } from '@angular/common';
import { BreakpointObserver } from '@angular/cdk/layout';
import {
  FormControl,
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { BehaviorSubject, Observable, lastValueFrom } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { TuiContextWithImplicit, tuiPure, TuiStringHandler } from '@taiga-ui/cdk';
import { APP_CONFIG, Language } from '@src/core';
import { AUTOCOMPLETE_TYPES } from '@src/constants';
import {
  AlertService,
  BreakpointObserverHelperService,
  JobTitleService,
  OrganisationService,
  RoleService,
  UserService,
} from '@src/core/services';
import { Multimedia, JobTitleUI, UserUI, ViewMode, ContactUI } from '@src/models';
import { GetUserContactsForUnionResponseDto, OrganisationRolesJobTitles, RoleView } from '@src/api';
import { DateToTuiDateTimePipe } from '@src/app/shared/pipes';
import { isFileContact } from '@src/utils';
import { ResizableBaseComponent } from '@src/app/components/resizable-base-component';
import { TranslateService } from '@ngx-translate/core';
import { formatPhone, inputPhoneFormValidator } from '@src/app/modules/phone';
import { Nullable, Optional } from '@src/types/utils';
import { CustomNamesService } from '@src/app/modules/custom-name-tabs';

import { MONTHS } from '../constants';
import { PermissionService } from '../services';

import { BirthMonth, FormData, FormDataControls, OrganisationRolesJobTitlesFormData } from './types';

@Component({
  selector: 'app-user-info-edit',
  templateUrl: './user-info-edit.component.html',
  styleUrls: ['./user-info-edit.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserInfoEditComponent extends ResizableBaseComponent implements OnChanges {
  @Input() mode: ViewMode = 'edit';
  @Input() data: UserUI | null = {};
  @Input() organisationId?: string | null;
  @Input() isParentOrganisation: boolean = false;
  @Input() contactsForUnion: Optional<Nullable<Array<GetUserContactsForUnionResponseDto>>>;
  @Output() saved: EventEmitter<UserUI> = new EventEmitter();
  @Output() canceled: EventEmitter<void> = new EventEmitter();
  @Output() onPhoneChange: EventEmitter<string> = new EventEmitter();
  @Output() deleteAuthUser: EventEmitter<string> = new EventEmitter();

  readonly allowIsDefaultOrganisationEditing$ = this.permService.allowIsDefaultOrganisationEditing$;
  readonly allowSpecialFieldsEditing$ = this.permService.allowSpecialFieldsEditing$;
  readonly allowSpecialFieldsForAssociationEditing$ = this.permService.allowSpecialFieldsForAssociationEditing$;
  readonly allowJobTitleFieldsEditing$ = this.permService.allowJobTitleFieldsEditing$;
  readonly allowJobTitlesForAdminOnlyEditing$ = this.permService.allowJobTitlesForAdminOnlyEditing$;
  readonly allowAssociationEmployeeEditing$ = this.permService.allowAssociationEmployeeEditing$;
  readonly allowOrganisationEmployeeEditing$ = this.permService.allowOrganisationEmployeeEditing$;
  readonly allowEmailFieldEditing$ = this.permService.allowEmailFieldEditing$;
  readonly allowContactFieldCreating$ = this.permService.allowContactFieldCreating$;
  readonly allowContactFieldEditing$ = this.permService.allowContactFieldEditing$;
  readonly allowContactFieldDeleting$ = this.permService.allowContactFieldDeleting$;

  readonly maxDocFileSize = APP_CONFIG.fileSizeMax.doc;
  readonly autocompleteTypes = AUTOCOMPLETE_TYPES;
  loading: boolean = true;
  jobsForViewing$: BehaviorSubject<JobTitleUI[]> = this.jobTitleService.jobTitles$;
  jobsForEditing$: Observable<JobTitleUI[]> = this.jobTitleService.jobTitles$.pipe(
    map(jobTitles =>
      jobTitles.filter(jobTitle => !jobTitle.isAdminOnly || this.allowJobTitlesForAdminOnlyEditing$.value),
    ),
  );
  rolesForAssociation$?: Observable<RoleView[]>;
  rolesForOrganisation$?: Observable<RoleView[]>;
  newPhoto?: Multimedia;
  form!: UntypedFormGroup;
  authUser$ = this.userService.authUser$;
  currentParentOrganisationId?: string;

  /** Используется для полей, которых нет в this.form при добавлении существующего пользователя. this.form.disabled нельзя использовать из-за того, что новую организацию можно редактировать */
  formDisabled: boolean = false;

  months: BirthMonth[] = MONTHS;

  constructor(
    readonly cdr: ChangeDetectorRef,
    readonly breakpointObserver: BreakpointObserver,
    readonly breakpointObserverHelperService: BreakpointObserverHelperService,
    private readonly permService: PermissionService,
    private readonly formBuilder: UntypedFormBuilder,
    private readonly datePipe: DatePipe,
    private readonly jobTitleService: JobTitleService,
    private readonly roleService: RoleService,
    private readonly userService: UserService,
    private readonly organisationService: OrganisationService,
    private readonly alertService: AlertService,
    private readonly dateToTuiDateTimePipe: DateToTuiDateTimePipe,
    private readonly customNameService: CustomNamesService,
    private readonly translateService: TranslateService,
  ) {
    super(cdr, breakpointObserver, breakpointObserverHelperService);

    this.initForm();
    this.organisationService.currentParentOrganisation$
      .pipe(takeUntil(this.destroyed$$))
      .subscribe(parentOrganisation => (this.currentParentOrganisationId = parentOrganisation?.id));
  }

  get organisations(): UntypedFormArray {
    return this.form.get('organisations') as UntypedFormArray;
  }

  get phoneControl(): UntypedFormControl {
    return this.form.get('phone') as UntypedFormControl;
  }

  // TODO: refactoring скорее всего нужно разнести логику создания и редактирования пользователя. в таком виде она непонятна
  async ngOnChanges(changes: SimpleChanges): Promise<void> {
    if (changes.mode) {
      this.initForm();
    }

    if (changes.data) {
      this.loading = true;

      // Необходимо дял привязки пользователя к нескольким организациям
      this.newPhoto = undefined;
      this.removeAllOrganisations();
      this.form.reset({
        phone: this.phoneControl?.value,
        isFeePaid: false,
      });
      this.form.enable();
      this.formDisabled = false;

      if (this.mode === 'create') {
        if (this.data) {
          if (!!this.data?.organisations?.find(organisation => organisation.organisationId === this.organisationId)) {
            this.alertService.error(
              this.translateService.instant('components.userInfo.alerts.errors.userSlredyExsist'),
              false,
            );

            this.data = null;
            this.loading = false;
            return;
          } else if (!this.allowJobTitlesForAdminOnlyEditing$.value) {
            this.alertService.error(
              this.translateService.instant('components.userInfo.alerts.errors.addingUserForbiden'),
              false,
            );

            this.data = null;
            this.loading = false;
            return;
          }
        }
      }

      if (this.data) {
        const mappedData = await this.mapUserUiToFormData(changes.data.currentValue);
        this.form.patchValue(mappedData);

        // Этот блок не может выполняться раньше this.form.patchValue(mappedData), т.к. сломаются роли в организациях
        if (this.mode === 'create') {
          this.form.disable();
          this.addOrganisation({ isDefault: false }); // Добавили блок с текущей организацией
          this.formDisabled = true;
        }
      } else {
        this.form.patchValue({ organisationId: this.organisationId ?? undefined }); // Указали текущую организацию дефолтной
        this.addOrganisation({ isDefault: true, organisationId: this.organisationId as string }); // Добавили блок с текущей организацией и поставили метку дефолтной организации
        this.setContactsForUnion(this.contactsForUnion); // Добавление стандартных доп полей после изменения номера телефона пользователя (если пользователь не найден)
      }

      this.loading = false;
    }

    if (changes.organisationId || changes.data) {
      this.rolesForAssociation$ = this.roleService.roles$.pipe(
        map(roles =>
          roles.filter(role => {
            if (!role.name) return false;
            return !['AdminDO', 'ParticipantDO'].includes(role.name); // TODO: строки заменить на RoleCode
          }),
        ),
      );

      this.rolesForOrganisation$ = this.roleService.roles$.pipe(
        map(roles =>
          roles.filter(role => {
            if (!role.name) return false;
            return !['AdminUO', 'ParticipantUO'].includes(role.name); // TODO: строки заменить на RoleCode
          }),
        ),
      );
    }

    if (changes.contactsForUnion) {
      this.setContactsForUnion(this.contactsForUnion);
    }

    this.cdr.markForCheck();
  }

  ngOnInit(): void {
    super.ngOnInit();

    this.jobTitleService.getJobTitles();
    this.roleService.getRoles();
  }

  async onChangePhone(phone: string) {
    this.phoneControl?.patchValue(phone);
    this.onPhoneChange.emit(phone);
  }

  onClickSaveButton(): void {
    this.loading = true;
    this.form.markAllAsTouched();

    if (this.form.invalid) {
      this.alertService.error(this.translateService.instant('common.alerts.errors.fillRequired'));
      this.loading = false;
      return;
    }

    const formData = this.mapFormDataToUserUI(this.form.getRawValue());
    this.newPhoto = undefined;

    this.saved.emit(formData);
    this.loading = false;
  }

  onClickDeleteButton() {
    if (this.authUser$.value?.id !== this.data?.id) return;

    this.deleteAuthUser.emit(this.authUser$.value?.id);
  }

  onClickCancelButton(): void {
    this.newPhoto = undefined;
    this.canceled.emit();
  }

  onPhotoChange(newPhoto?: Multimedia): void {
    this.newPhoto = newPhoto;
    this.cdr.markForCheck();
  }

  readonly stringifyJobTitle: TuiStringHandler<any | TuiContextWithImplicit<any>> = item => {
    return this.jobsForViewing$.value.find(jobs => jobs.jobTitleId === item)?.jobTitleName ?? '';
  };

  @tuiPure
  rolesStringify(items: ReadonlyArray<RoleView>): TuiStringHandler<TuiContextWithImplicit<string[]>> {
    const map = new Map(items.map(({ id, description }) => [id, description] as [string, string]));
    return ({ $implicit }: TuiContextWithImplicit<string[]>) => map.get($implicit[0]) || '';
  }

  @tuiPure
  monthStringify(months: readonly BirthMonth[]): TuiStringHandler<TuiContextWithImplicit<number>> {
    const map = new Map(months.map(({ id, name }) => [id, name] as [number, string]));
    return ({ $implicit }: TuiContextWithImplicit<number>) => this.translateService.instant(map.get($implicit) || ' ');
  }

  isDefaultOrganisation(index: number): boolean {
    const organisation = this.organisations?.at(index)?.value as OrganisationRolesJobTitlesFormData;
    return !!organisation?.isDefault;
  }

  setDefaultOrganisation(index: number): void {
    this.organisations.controls.forEach(organisationControl => {
      organisationControl.get('isDefault')?.patchValue(false);
    });
    const organisationControl = this.organisations.at(index);
    organisationControl.get('isDefault')?.patchValue(true);
    this.form.get('organisationId')?.patchValue(organisationControl.get('organisationId')?.value);
  }

  private initForm(): void {
    this.form = new UntypedFormGroup(<FormDataControls>{
      id: new UntypedFormControl(undefined),
      firstName: new UntypedFormControl(undefined, [Validators.required, Validators.maxLength(50)]),
      middleName: new UntypedFormControl(undefined, Validators.maxLength(50)),
      lastName: new UntypedFormControl(undefined, [Validators.required, Validators.maxLength(50)]),
      phone: new UntypedFormControl(
        undefined,
        this.allowSpecialFieldsEditing$.value ? [Validators.required, inputPhoneFormValidator()] : [],
      ),
      email: new UntypedFormControl(undefined, Validators.required),
      organisationId: new UntypedFormControl(undefined),
      jobDescription: new UntypedFormControl(undefined),
      organisations: this.formBuilder.array([]),
      birthDate: new UntypedFormControl(undefined),
      birthDateDay: new UntypedFormControl(undefined),
      birthDateMonth: new UntypedFormControl(undefined),
      birthDateYear: new UntypedFormControl(undefined),

      resources: new UntypedFormControl(undefined),
      hobbies: new UntypedFormControl(undefined),
      isFeePaid: new UntypedFormControl(false),
      paymentDetails: new UntypedFormControl(undefined),
      oldPaymentDetails: new UntypedFormControl(null),
      authorityValidTill: new UntypedFormControl(undefined),
      contacts: new FormControl<ContactUI[]>([], { nonNullable: true }), // TODO: переписать на нормальный тип когда будем избавляться от UntypedFormControl

      curator: new UntypedFormControl(null),
    });
  }

  private async mapUserUiToFormData(user: UserUI) {
    const {
      id,
      firstName,
      lastName,
      middleName,
      email,
      resources,
      hobbies,
      isFeePaid,
      paymentDetailsId,
      curatorId,
      organisations,
    } = user;
    let curatorFullName = '';

    let phone: string | undefined = '0'; // TODO: HACK for edit user api

    if (this.allowSpecialFieldsEditing$.value) {
      phone = formatPhone(user.phone);
    }

    if (organisations) {
      for (const organisation of organisations) {
        await this.addOrganisation(organisation, user);
      }
    }

    const birthDate = user.birthDate ? new Date(user.birthDate) : undefined;
    const birthDateYear = birthDate?.getFullYear() !== 2999 ? birthDate?.getFullYear() : undefined;
    const birthDateString = birthDate
      ? birthDateYear
        ? this.datePipe.transform(birthDate, 'dd MMMM y')
        : this.datePipe.transform(birthDate, 'dd MMMM')
      : undefined;

    const authorityValidTill = this.dateToTuiDateTimePipe.transform(user.authorityValidTill, 'date');

    const oldPaymentDetails = paymentDetailsId
      ? { id: paymentDetailsId, name: this.customNameService.getItem('label.userProfileAttachment')?.title }
      : null;

    const contacts = id ? await this.userService.getUserContactsData(id) : [];

    if (curatorId) {
      const curator = await lastValueFrom(this.userService.getUserData(curatorId));
      curatorFullName = curator.fullName ?? '';
    }

    return <FormData>{
      id,
      firstName,
      middleName,
      lastName,
      phone,
      email,
      birthDate: birthDateString,
      birthDateDay: birthDate?.getDate(),
      birthDateMonth: birthDate ? birthDate.getMonth() + 1 : undefined,
      birthDateYear,
      resources,
      hobbies,
      isFeePaid,
      authorityValidTill,
      oldPaymentDetails,
      curator: curatorId ? { id: curatorId, fullName: curatorFullName } : undefined, // TODO: Заменить { ... } на curator после решения задачи #10334
      contacts: this.normalizeContacts(contacts),
    };
  }

  private mapFormDataToUserUI(formData: FormData) {
    const {
      id,
      firstName,
      middleName,
      lastName,
      email,
      jobDescription,
      birthDateDay,
      resources,
      hobbies,
      isFeePaid,
      paymentDetails,
      oldPaymentDetails,
      curator,
    } = formData;

    let organisationsToAdd: OrganisationRolesJobTitles[] | undefined;

    const birthDateMonth = formData.birthDateMonth;
    const birthDateYear = formData.birthDateYear ?? 2999;
    const birthDate =
      birthDateDay && birthDateMonth ? new Date(birthDateYear, birthDateMonth - 1, birthDateDay, 0, 0, 0) : null;

    const authorityValidTill = formData.authorityValidTill
      ? new Date(formData.authorityValidTill.year, formData.authorityValidTill.month, formData.authorityValidTill.day)
      : undefined;

    // TODO: refactoring
    let organisations = formData.organisations.map(organisation => {
      return <OrganisationRolesJobTitles>{
        organisationId: organisation.organisationId,
        isDefault: organisation.isDefault,
        jobTitles: organisation.jobTitlesIds?.length
          ? organisation.jobTitlesIds.map(jobTitleId => {
              return {
                id: jobTitleId,
                description: organisation.jobTitlesDescription,
              };
            })
          : [{ description: organisation.jobTitlesDescription }],
        roleIds: organisation.roleIds,
      };
    });

    if (this.mode === 'create' && this.data) {
      const organisationToAdd = organisations.find(organisation => {
        return organisation.organisationId === this.organisationId;
      });

      if (organisationToAdd) {
        organisationsToAdd = [organisationToAdd];
      }

      organisations = organisations.filter(organisation => organisation.organisationId !== this.organisationId);
    }

    const contacts = formData.contacts.map((contact: ContactUI) => {
      if (!contact.id) {
        delete contact.id;
      }
      return contact;
    });

    return <UserUI>{
      id,
      firstName,
      middleName,
      lastName,
      phone: formData.phone,
      email,
      birthDate: birthDate ? formatDate(birthDate, 'yyyy-MM-dd', Language.EN) : undefined,
      organisations,
      jobTitleDescription: jobDescription, // TODO: fix bug. need refactoring
      photo: this.newPhoto?.file,

      resources,
      hobbies,
      isFeePaid: isFeePaid !== null ? isFeePaid : undefined,
      authorityValidTill,
      paymentDetails,
      deletePaymentDetailsId: !oldPaymentDetails && this.data?.paymentDetailsId ? this.data.paymentDetailsId : null,

      curatorId: curator?.id,
      organisationsToAdd,
      contacts,
    };
  }

  private async createOrganisation(
    organisation?: OrganisationRolesJobTitles,
    user?: UserUI,
  ): Promise<UntypedFormGroup> {
    const organisationJobTitle = user?.organisationJobTitles?.find(
      organisationJobTitle => organisationJobTitle.organisationId === organisation?.organisationId,
    );
    const organisationName =
      organisationJobTitle?.organisationName ?? this.organisationService.organisation$?.value?.shortName;

    let roleIds: string[] | undefined = ['00000000-0000-0000-0000-000000000001']; // TODO: HACK for edit user api
    let jobTitles: JobTitleUI[] = [];

    if (this.allowSpecialFieldsEditing$.value && organisation?.organisationId && user?.id) {
      const userRoles = await this.roleService.getUserRolesData(user.id, organisation.organisationId);
      roleIds = userRoles[0]?.id ? [userRoles[0].id] : undefined;
    }

    if (user && this.allowSpecialFieldsForAssociationEditing$.value) {
      jobTitles = this.jobsForViewing$.value.filter(
        job =>
          user.organisationJobTitles &&
          user.organisationJobTitles.findIndex(
            organisationJobTitle => organisationJobTitle.jobTitleId === job.jobTitleId,
          ) > -1,
      );
    }

    const jobTitlesIds: string[] | undefined = organisation?.jobTitles
      ?.filter(jobTitle => Boolean(jobTitle.id))
      .map(jobTitle => jobTitle.id!);
    const jobTitlesDescription = organisation?.jobTitles?.[0].description ?? '';

    return this.formBuilder.group({
      organisationId: new UntypedFormControl(organisation?.organisationId ?? this.organisationId),
      organisationName: new UntypedFormControl(organisationName),
      jobTitles: new UntypedFormControl(jobTitles),
      jobTitlesIds: new UntypedFormControl(
        jobTitlesIds,
        this.allowSpecialFieldsForAssociationEditing$.value ? Validators.required : undefined,
      ),
      jobTitlesDescription: new UntypedFormControl(jobTitlesDescription),
      roleIds: new UntypedFormControl(roleIds, this.allowSpecialFieldsEditing$.value ? Validators.required : undefined),
      isDefault: new UntypedFormControl(organisation?.isDefault),
    });
  }

  private async addOrganisation(organisation?: OrganisationRolesJobTitles, user?: UserUI): Promise<void> {
    if (
      this.allowAssociationEmployeeEditing$.value ||
      (this.allowOrganisationEmployeeEditing$.value && organisation?.organisationId === this.organisationId) ||
      user?.id === this.authUser$?.value?.id
    ) {
      const organisationFormGroup = await this.createOrganisation(organisation, user);
      if (this.mode === 'create' && organisationFormGroup.get('organisationId')?.value !== this.organisationId) {
        organisationFormGroup.disable();
      }
      this.organisations.push(organisationFormGroup);
      this.cdr.markForCheck();
    }
  }

  private removeAllOrganisations(): void {
    for (let i = this.organisations.length - 1; i >= 0; i--) {
      this.organisations.removeAt(i);
    }
  }

  private normalizeContacts(contacts: ContactUI[]) {
    return contacts.map((contact: ContactUI) => {
      if (isFileContact(contact.contactTypeId) && contact.contact) {
        contact.oldDocument = {
          id: contact.contact,
          name: contact.description ?? this.translateService.instant('components.userInfo.labels.file'),
        };
      }

      return contact;
    });
  }

  private setContactsForUnion(contactsForUnion: Optional<Nullable<Array<GetUserContactsForUnionResponseDto>>>) {
    if (contactsForUnion) {
      this.form.patchValue({ contacts: this.normalizeContacts(contactsForUnion) });
    }
  }
}

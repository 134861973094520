import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { JobTitlesComponent, BusinessTypesComponent } from '@src/app/modules/dictionaries';

import { AdministrationComponent } from './administration.component';
import { NewUsersComponent } from './new-users/new-users.component';
import { NewAssociationComponent } from './new-association/new-association.component';
import { CustomNamesComponent } from './custom-names/custom-names.component';
import { ReportsComponent } from './reports/reports.component';
import { ImportComponent } from './import/import.component';
import { MailComponent } from './mail/mail.component';
import { StandardUserInfoFieldsComponent } from './standard-user-info-fields/standard-user-info-fields.component';

const moduleRoutes: Routes = [
  {
    path: '',
    component: AdministrationComponent,
    children: [
      { path: 'new-users', component: NewUsersComponent },
      { path: 'new-association', component: NewAssociationComponent },
      { path: 'custom-names', component: CustomNamesComponent },
      { path: 'select-job-titles', component: JobTitlesComponent },
      { path: 'select-business-types', component: BusinessTypesComponent },
      { path: 'import', component: ImportComponent },
      { path: 'mail', component: MailComponent },
      { path: 'reports', component: ReportsComponent },
      { path: 'standard-user-info-fields', component: StandardUserInfoFieldsComponent },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(moduleRoutes)],
  exports: [RouterModule],
})
export class AdministrationRouting {}

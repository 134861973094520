import { DiscountGroupLoyalty, LoyaltyProductWCategiriesAdd } from '@src/api';

import { LoyaltyProgramProductInput } from '../../models';
import { QrCodeImageIndexId } from '../types';

interface ConvertFromProductInputProps {
  product: LoyaltyProgramProductInput;
  imagesIds: string[];
  coverImageId?: string;
  qrCodeImages: QrCodeImageIndexId;
}

export const convertFromProductInput = ({
  product,
  imagesIds,
  coverImageId,
  qrCodeImages,
}: ConvertFromProductInputProps): LoyaltyProductWCategiriesAdd => {
  const { company, persons } = product.contacts;
  const {
    paidBoostRateId,
    paidDuration,
    paidNewsletterTier1Count,
    paidNewsletterTier2Count,
    paidNewsletterTier3Count,
    requestLeadContacts,
  } = product.cost;

  return {
    name: product.name,
    description: product.description,
    eridAdInfo: product.eridAdInfo,
    eridToken: product.eridToken,
    organisationId: company.id,
    organisationInfo: company.info,
    categoryIds: product.categories.map(item => item.id),
    regionIds: product.regions?.map(item => item.id),
    discountGroups: product.terms.map<DiscountGroupLoyalty>((term, index) => ({
      id: term.id,
      discountRate: term.discountType === 'rate' ? Number(term.discount) : undefined,
      discountAbsolute: term.discountType === 'cash' ? Number(term.discount) : undefined,
      discountText: term.discountType === 'tag' ? term.discount.toString() : undefined,
      discountOptions: term.description,
      discountCode: term.promoCode,
      generateQRCode: term.showAsQRCode ?? false,
      discountQRCodeFileId: qrCodeImages?.[index],
      jobTitles: term.jobs.map<string>(job => job.id),
    })),
    contacts: persons,
    photos: imagesIds.map((id, index) => ({ id, sortOrder: index * 10, isCover: id === coverImageId })),
    contactUserId: product.contacts.mentor?.id ?? undefined,
    // @ts-ignore поле не описано в схеме
    contactUserFIO: product.contacts.mentor?.name ?? undefined,
    paidBoostRateId,
    paidDuration,
    paidNewsletterTier1Count,
    paidNewsletterTier2Count,
    paidNewsletterTier3Count,
    requestLeadContacts,
  };
};

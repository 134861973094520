<form class="registration-form" [formGroup]="form">
  <div class="registration-form__block">
    <tui-input
      tuiAutoFocus
      tabindex="1"
      [class.tui-skeleton]="loading"
      [class.tui-skeleton_text]="loading"
      formControlName="lastName"
      [tuiTextfieldSize]="tuiElementLargeSize"
    >
      {{ 'components.userInfo.fields.lastName' | translate }}
      <span class="tui-required"></span>
      <input tuiTextfield [attr.autocomplete]="autocompleteTypes.lastName" />
    </tui-input>

    <tui-input
      tabindex="2"
      formControlName="firstName"
      [class.tui-skeleton]="loading"
      [class.tui-skeleton_text]="loading"
      [tuiTextfieldSize]="tuiElementLargeSize"
    >
      {{ 'components.userInfo.fields.firstName' | translate }}
      <span class="tui-required"></span>
      <input tuiTextfield [attr.autocomplete]="autocompleteTypes.firstName" />
    </tui-input>

    <tui-input
      tabindex="3"
      formControlName="middleName"
      [class.tui-skeleton]="loading"
      [class.tui-skeleton_text]="loading"
      [tuiTextfieldSize]="tuiElementLargeSize"
    >
      {{ 'components.userInfo.fields.middleName' | translate }}
      <input tuiTextfield [attr.autocomplete]="autocompleteTypes.middleName" />
    </tui-input>

    <!-- FIXME: app-input-phone -->
    <app-input-phone
      tabindex="4"
      formControlName="phone"
      [class.tui-skeleton]="loading"
      [class.tui-skeleton_text]="loading"
      [size]="tuiElementLargeSize"
      [phoneValue]="phoneControl.value"
      (phoneValueChange)="onChangePhone($event)"
      [pseudoInvalid]="phoneControl.invalid"
      [forceDisabled]="false"
    >
      {{ 'components.userInfo.fields.mobilePhone' | translate }}
      <span class="tui-required"></span>
    </app-input-phone>

    <tui-input
      tabindex="5"
      formControlName="email"
      [class.tui-skeleton]="loading"
      [class.tui-skeleton_text]="loading"
      [tuiTextfieldSize]="tuiElementLargeSize"
    >
      {{ 'components.userInfo.fields.email' | translate }}
      <span class="tui-required"></span>
      <input tuiTextfield [attr.autocomplete]="autocompleteTypes.email" />
    </tui-input>
  </div>

  <app-contacts class="registration-form__contacts" formControlName="contacts" [loading]="loading"></app-contacts>

  <button
    tuiButton
    class="button"
    tabindex="6"
    type="button"
    appearance="primary"
    [size]="tuiElementLargeSize"
    (click)="onClickSendButton()"
  >
    {{ 'common.buttons.send' | translate }}
  </button>
</form>

import { Component, ChangeDetectionStrategy, OnChanges, SimpleChanges, Input, ChangeDetectorRef } from '@angular/core';
import { Observable } from 'rxjs';

import { ReportsService } from './services';
import { GetReportForUnionResultDtoUI, ReportType } from './types';

@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReportsComponent implements OnChanges {
  @Input() reportType?: ReportType;

  reports$: Observable<Array<GetReportForUnionResultDtoUI>> | null = null;

  constructor(private readonly cdr: ChangeDetectorRef, private readonly reportsService: ReportsService) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['reportType']) {
      if (this.reportType) {
        this.reports$ = this.reportsService.getReports(this.reportType);
      } else {
        this.reports$ = null;
      }
    }

    this.cdr.markForCheck();
  }
}

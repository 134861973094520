<a
  *ngIf="urlType === 'external'; else internal"
  tuiLink
  [title]="normalizedDisplayableText"
  [pseudo]="pseudo"
  [icon]="icon"
  [iconAlign]="iconAlign"
  [mode]="mode"
  (click)="openLink()"
>
  <ng-container [ngTemplateOutlet]="content"></ng-container>
</a>

<ng-template #internal>
  <a
    tuiLink
    icon="tuiIconExternal"
    [title]="normalizedDisplayableText"
    [pseudo]="pseudo"
    [icon]="icon"
    [iconAlign]="iconAlign"
    [mode]="mode"
    (click)="gotoInnerLink()"
  >
    <ng-container [ngTemplateOutlet]="content"></ng-container>
  </a>
</ng-template>

<ng-template #content>
  <span>{{ normalizedDisplayableText }}</span>
  <ng-content></ng-content>
</ng-template>

<ng-template #tmpRedirectToUrl let-observer>
  <div class="prompt">
    <div>
      <h1>{{ 'components.link.dialogs.redirectToUrlTitle' | translate }}</h1>

      <p>
        {{ 'components.link.dialogs.redirectToUrlDescription' | translate }}
        <strong>{{ normalizedUrl }}</strong>
      </p>
    </div>

    <div class="prompt__actions">
      <button tuiButton type="button" appearance="secondary" size="m" (click)="observer.complete()">
        {{ 'common.buttons.cancel' | translate }}
      </button>

      <button tuiButton type="button" appearance="primary" size="m" (click)="gotoExternalLink(observer)">
        {{ 'common.buttons.goTo' | translate }}
      </button>
    </div>
  </div>
</ng-template>

import { Injectable } from '@angular/core';
import { NgxPermissionsService } from 'ngx-permissions';
import { EnvService } from '@src/app/modules/env';

@Injectable({
  providedIn: 'root',
})
export class LoyaltyProgramPermService {
  private permissions = this.ngxPermissionsService.getPermissions();

  constructor(private ngxPermissionsService: NgxPermissionsService, private env: EnvService) {}

  get allowedCreating(): boolean {
    return 'loyaltyProgramCreating' in this.permissions && this.isNotCordova;
  }

  get allowedEditing(): boolean {
    return 'loyaltyProgramEditing' in this.permissions && this.isNotCordova;
  }

  get allowedDeleting(): boolean {
    return 'loyaltyProgramDeleting' in this.permissions;
  }

  get allowedPublishing(): boolean {
    return 'loyaltyProgramPublishing' in this.permissions;
  }

  get allowedUnpublishing(): boolean {
    return 'loyaltyProgramUnpublishing' in this.permissions;
  }

  get allowedAllStatisticsViewing(): boolean {
    return 'loyaltyProgramAllStatisticsViewing' in this.permissions;
  }

  // TODO: isNotCordova - временное решение для задачи https://redmine.tbnsoft.ru/issues/9437
  get isNotCordova() {
    return !this.env.isCordova;
  }
}

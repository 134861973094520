import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { DeepLinksService } from './deep-links.service';

@NgModule({
  imports: [CommonModule],
  providers: [DeepLinksService],
})
export class DeepLinksModule {}

<div class="birthdays">
  <div class="birthdays__header">
    <app-header [options]="headerOptions"></app-header>
  </div>

  <div class="birthdays__list">
    <div class="search">
      <tui-input-date-range tuiTextfieldSize="s" [formControl]="periodControl">
        {{ 'components.birthdaysList.fields.period' | translate }}
      </tui-input-date-range>
    </div>

    <tui-scrollbar>
      <app-new-list
        [items]="items$ | async"
        [(selectedId)]="selectedId"
        (selectedIdChange)="onChangeSelectedId($event)"
        [itemContentTmp]="listItemTmp"
      ></app-new-list>
    </tui-scrollbar>
  </div>
</div>

<ng-template #listItemTmp let-user>
  <app-new-list-item-user [user]="user" [birthDateContent]="tagTmp"></app-new-list-item-user>
</ng-template>

<ng-template #tagTmp let-value>
  <birthdays-tag [value]="value"></birthdays-tag>
</ng-template>

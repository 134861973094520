import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import {
  TuiInputFilesModule,
  TuiInputModule,
  TuiInputNumberModule,
  TuiIslandModule,
  TuiSelectModule,
  TuiTextareaModule,
} from '@taiga-ui/kit';
import { TuiButtonModule, TuiDataListModule, TuiTextfieldControllerModule } from '@taiga-ui/core';
import { TranslateModule } from '@ngx-translate/core';
import { ComponentsModule } from '@src/app/components';

import { ContactsComponent } from './contacts.component';
import {
  ContactsSortOrderComponent,
  ContactsTypeComponent,
  ContactsNameComponent,
  ContactsValueFileComponent,
  ContactsValueTextComponent,
  ContactsValueTextareaComponent,
  ContactsViewIslandComponent,
  ContactsViewOldComponent,
} from './components';

@NgModule({
  declarations: [
    ContactsComponent,
    ContactsNameComponent,
    ContactsSortOrderComponent,
    ContactsTypeComponent,
    ContactsValueFileComponent,
    ContactsValueTextComponent,
    ContactsValueTextareaComponent,
    ContactsViewIslandComponent,
    ContactsViewOldComponent,
  ],
  imports: [
    CommonModule,
    ComponentsModule,
    ReactiveFormsModule,
    TuiInputModule,
    TuiInputNumberModule,
    TuiTextfieldControllerModule,
    TuiSelectModule,
    TuiDataListModule,
    TuiInputFilesModule,
    TuiTextareaModule,
    TuiIslandModule,
    TuiButtonModule,
    TranslateModule,
  ],
  exports: [ContactsComponent],
})
export class ContactsModule {}

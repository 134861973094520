<form *ngIf="!!data" class="event-info-view" [formGroup]="infoForm">
  <div class="event-info-view__block">
    <app-photo
      *ngIf="!!data?.photoId"
      class="image"
      [class.tui-skeleton]="loading"
      [class.tui-skeleton_text]="loading"
      [text]="data.subject"
      [photoId]="data.photoId"
      [size]="tuiElementDoubleExtraLargeSize"
      [rounded]="true"
      [loading]="loading"
    ></app-photo>

    <app-field
      class="field"
      tabindex="2"
      [name]="'components.eventInfo.fields.name' | translate"
      [class.tui-skeleton]="loading"
      [class.tui-skeleton_text]="loading"
      [typeName]="'text'"
      [value]="infoForm.get('subject')?.value"
      [size]="tuiElementLargeSize"
    ></app-field>

    <tui-select
      *ngIf="infoForm.get('eventTypeId')?.value"
      class="field"
      tabindex="3"
      formControlName="eventTypeId"
      [valueContent]="eventTypes ? eventTypesStringify(eventTypes) : ''"
      [tuiTextfieldSize]="tuiElementLargeSize"
      [class.tui-skeleton]="loading"
      [class.tui-skeleton_text]="loading"
      [readOnly]="true"
    >
      {{ 'components.eventInfo.fields.eventType' | translate }}
      <tui-data-list *tuiDataList>
        <button *ngFor="let eventType of eventTypes" tuiOption [value]="eventType.id">
          {{ eventType.name | translate }}
        </button>
      </tui-data-list>
    </tui-select>

    <app-field
      *ngIf="infoForm.get('description')?.value"
      class="field"
      tabindex="4"
      [name]="'components.eventInfo.fields.description' | translate"
      [class.tui-skeleton]="loading"
      [class.tui-skeleton_text]="loading"
      [typeName]="'parse-links'"
      [value]="infoForm.get('description')?.value"
      [size]="tuiElementLargeSize"
    ></app-field>

    <app-field
      *ngIf="infoForm.get('address')?.value"
      class="field"
      tabindex="5"
      [name]="'components.eventInfo.fields.address' | translate"
      [class.tui-skeleton]="loading"
      [class.tui-skeleton_text]="loading"
      [typeName]="'text'"
      [value]="infoForm.get('address')?.value"
      [size]="tuiElementLargeSize"
    ></app-field>

    <app-field
      *ngIf="infoForm.get('onlineLink')?.value"
      class="field"
      tabindex="6"
      [name]="'components.eventInfo.fields.onlineLink' | translate"
      [class.tui-skeleton]="loading"
      [class.tui-skeleton_text]="loading"
      [typeName]="'parse-links'"
      [value]="infoForm.get('onlineLink')?.value"
      [size]="tuiElementLargeSize"
    ></app-field>

    <tui-input-date-time
      class="field"
      tabindex="7"
      formControlName="dateStart"
      [class.tui-skeleton]="loading"
      [class.tui-skeleton_text]="loading"
      [tuiTextfieldSize]="tuiElementLargeSize"
      [readOnly]="true"
    >
      {{ 'components.eventInfo.fields.dateStart' | translate }}
    </tui-input-date-time>

    <tui-input-date-time
      class="field"
      tabindex="8"
      formControlName="dateEnd"
      [class.tui-skeleton]="loading"
      [class.tui-skeleton_text]="loading"
      [tuiTextfieldSize]="tuiElementLargeSize"
      [readOnly]="true"
    >
      {{ 'components.eventInfo.fields.dateEnd' | translate }}
    </tui-input-date-time>

    <div
      *ngIf="infoForm.get('committeeId')?.value"
      class="field"
      tabindex="9"
      [class.tui-skeleton]="loading"
      [class.tui-skeleton_text]="loading"
    >
      <app-field
        [typeName]="'committee'"
        [value]="infoForm.get('committeeId')?.value"
        [size]="tuiElementLargeSize"
      ></app-field>
    </div>

    <app-field
      *ngIf="infoForm.get('contactPersonId')?.value"
      class="field"
      tabindex="10"
      [name]="'components.eventInfo.fields.contactPerson' | translate"
      [class.tui-skeleton]="loading"
      [class.tui-skeleton_text]="loading"
      [typeName]="'user'"
      [value]="infoForm.get('contactPersonId')?.value"
      [size]="tuiElementLargeSize"
    ></app-field>

    <div *ngIf="program" class="field" tabindex="11" [class.tui-skeleton]="loading" [class.tui-skeleton_text]="loading">
      <app-field
        [name]="'components.eventInfo.fields.program' | translate"
        [typeName]="'file'"
        [value]="program.id"
        [size]="tuiElementLargeSize"
      ></app-field>
    </div>
  </div>
  <div *ngIf="!loading" class="event-info-view__buttons">
    <app-copy-to-clipboard-button [link]="getLinkToClipboard()"></app-copy-to-clipboard-button>

    <div tuiGroup class="group" *ngIf="isAcceptButtonEnabled || isDeclineButtonEnabled">
      <button
        *ngIf="isAcceptButtonEnabled"
        tuiButton
        class="event-info-view-button"
        type="button"
        appearance="positive"
        [size]="tuiElementLargeSize"
        (click)="onClickAcceptButton()"
      >
        {{ 'components.eventInfo.buttons.accept' | translate }}
      </button>
      <button
        *ngIf="isDeclineButtonEnabled"
        tuiButton
        class="event-info-view-button"
        type="button"
        appearance="accent"
        [size]="tuiElementLargeSize"
        (click)="onClickDeclineButton()"
      >
        {{ 'common.buttons.decline' | translate }}
      </button>
    </div>
    <button
      tuiButton
      class="event-info-view-button"
      type="button"
      appearance="primary"
      [size]="tuiElementLargeSize"
      (click)="onClickToCalendarButton()"
    >
      {{ 'components.eventInfo.buttons.addToCalendar' | translate }}
    </button>
    <tui-loader *ngIf="allowGoToChat" [showLoader]="chatButtonLoading" [inheritColor]="true" [overlay]="true">
      <button
        tuiButton
        class="event-info-view-button"
        type="button"
        appearance="primary"
        [size]="tuiElementLargeSize"
        (click)="onClickToChatButton()"
      >
        {{ 'common.buttons.goToChat' | translate }}
      </button>
    </tui-loader>

    <tui-loader
      *ngIf="allowEditing && memberIdsToRecall?.length"
      [showLoader]="recallButtonLoading"
      [inheritColor]="true"
      [overlay]="true"
    >
      <button
        tuiButton
        class="event-info-view-button"
        type="button"
        appearance="primary"
        [size]="tuiElementLargeSize"
        (click)="onClickRecallButton()"
      >
        {{ 'components.eventInfo.buttons.recall' | translate }}
      </button>
    </tui-loader>

    <button
      *ngIf="allowReportViewing"
      tuiButton
      class="event-info-view-button"
      type="button"
      appearance="primary"
      [size]="tuiElementLargeSize"
      (click)="onClickReportButton()"
    >
      {{ 'components.eventInfo.buttons.report' | translate }}
    </button>

    <button
      *ngIf="allowEditing"
      tuiButton
      class="event-info-view-button"
      type="button"
      appearance="primary"
      [size]="tuiElementLargeSize"
      (click)="onClickEditButton()"
    >
      {{ 'common.buttons.edit' | translate }}
    </button>

    <button
      *ngIf="allowEditing"
      tuiButton
      class="event-info-view-button"
      type="button"
      appearance="secondary"
      [size]="tuiElementLargeSize"
      (click)="onClickCopyButton()"
    >
      {{ 'common.buttons.copy' | translate }}
    </button>

    <div tuiGroup class="group">
      <button
        *ngIf="allowEditing && data?.status !== 5"
        tuiButton
        class="event-info-view-button"
        type="button"
        appearance="accent"
        [size]="tuiElementLargeSize"
        (click)="onClickDeactivateButton()"
      >
        {{ 'common.buttons.cancel' | translate }}
      </button>
      <button
        *ngIf="allowEditing"
        tuiButton
        class="event-info-view-button"
        type="button"
        appearance="accent"
        [size]="tuiElementLargeSize"
        (click)="onClickDeleteButton()"
      >
        {{ 'common.buttons.delete' | translate }}
      </button>
    </div>
  </div>
  <div class="event-info-view__block">
    <div
      *ngIf="additionalDocs?.length"
      class="field"
      tabindex="12"
      [class.tui-skeleton]="loading"
      [class.tui-skeleton_text]="loading"
    >
      <app-field
        [name]="'components.eventInfo.fields.additionalDocs' | translate"
        [typeName]="'files-list'"
        [value]="additionalDocs"
        [size]="tuiElementLargeSize"
      ></app-field>
    </div>
    <ng-container *ngxPermissionsOnly="'eventMembersViewing'">
      <div
        *ngIf="membersList?.length"
        class="field"
        tabindex="13"
        [class.tui-skeleton]="loading"
        [class.tui-skeleton_text]="loading"
      >
        <div class="members-list-title">
          {{
            membersList
              ? (membersList.length
                | numeralPlurals
                  : [
                      'components.eventInfo.labels.memberOne' | translate,
                      'components.eventInfo.labels.memberFew' | translate,
                      'components.eventInfo.labels.memberMany' | translate
                    ])
              : ''
          }}
          <button
            tuiIconButton
            class="members-list-title__button"
            type="button"
            appearance="icon"
            icon="tuiIconDownload"
            size="xs"
            [title]="'components.eventInfo.buttons.downloadMembersFile' | translate"
            (click)="onClickMembersFileButton()"
          ></button>
        </div>
        <app-users-table mode="view" [users]="membersList"></app-users-table>
      </div>
    </ng-container>
  </div>
</form>

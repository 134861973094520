import { BreakpointObserver } from '@angular/cdk/layout';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { ResizableBaseComponent } from '@src/app/components';
import { inputPhoneFormValidator } from '@src/app/modules/phone';
import { AUTOCOMPLETE_TYPES } from '@src/constants';
import { BreakpointObserverHelperService, AlertService } from '@src/core/services';
import { lastValueFrom, takeUntil } from 'rxjs';
import { isFileContact } from '@src/utils';
import { requireContactsValidator } from '@src/app/modules/contacts';

import { RegistrationService } from '../../services/registration.service';
import { FormDataControls, RegistrationFormContactUI, RegistrationFormUI } from '../../types';

@Component({
  selector: 'registration-form',
  templateUrl: './registration-form.component.html',
  styleUrls: ['./registration-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RegistrationFormComponent extends ResizableBaseComponent implements OnInit, OnChanges {
  @Input() associationId?: string;
  @Input() loading: boolean = false;

  @Output() saved: EventEmitter<RegistrationFormUI> = new EventEmitter<RegistrationFormUI>();

  readonly form = new UntypedFormGroup(<FormDataControls>{
    firstName: new UntypedFormControl(undefined, Validators.required),
    middleName: new UntypedFormControl(undefined),
    lastName: new UntypedFormControl(undefined, Validators.required),
    phone: new UntypedFormControl(undefined, [Validators.required, inputPhoneFormValidator()]),
    email: new UntypedFormControl(undefined, Validators.required),
    contacts: new UntypedFormControl(undefined, requireContactsValidator()),
  });

  readonly autocompleteTypes = AUTOCOMPLETE_TYPES;

  constructor(
    readonly cdr: ChangeDetectorRef,
    readonly breakpointObserver: BreakpointObserver,
    readonly breakpointObserverHelperService: BreakpointObserverHelperService,
    private readonly alertService: AlertService,
    private readonly translateService: TranslateService,
    private readonly registrationService: RegistrationService,
  ) {
    super(cdr, breakpointObserver, breakpointObserverHelperService);
  }

  get phoneControl(): UntypedFormControl {
    return this.form.get('phone') as UntypedFormControl;
  }

  ngOnInit(): void {
    this.registrationService.userContacts$.pipe(takeUntil(this.destroyed$$)).subscribe(contacts => {
      this.form.get('contacts')?.patchValue(contacts);
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.associationId && this.associationId) {
      this.registrationService.getUserContactsForUnion(this.associationId);
    }
  }

  async onChangePhone(phone: string) {
    this.phoneControl?.patchValue(phone);
  }

  async onClickSendButton(): Promise<void> {
    this.form.markAllAsTouched();

    if (this.form.invalid) {
      this.alertService.error(this.translateService.instant('common.alerts.errors.fillRequired'));
      return;
    }

    const formData = await this.mapFormDataToApiRequestType(this.form.value);

    this.saved.emit(formData);
  }

  private async mapFormDataToApiRequestType(formData: RegistrationFormUI) {
    const { firstName, lastName, middleName, email } = formData;
    let contacts: RegistrationFormContactUI[] = [];

    if (formData.contacts) {
      const promises = formData.contacts.map(async contact => {
        if (isFileContact(contact.contactTypeId)) {
          if (contact.newDocument && this.associationId) {
            contact.value = await this.uploadDocument(contact.newDocument, this.associationId);
          }

          contact.newDocument = undefined;
        } else {
          contact.value = contact.contact;
        }

        return contact;
      });

      contacts = await Promise.all(promises);
    }

    const phone = formData.phone?.replace(new RegExp('[+ -]', 'g'), '');

    return <RegistrationFormUI>{
      parentOrganisationId: this.associationId,
      firstName,
      middleName,
      lastName,
      phone,
      email,
      contacts,
    };
  }

  private async uploadDocument(document: File, parentOrganisationId: string): Promise<string | undefined> {
    if (!document) return;

    const documentId = await lastValueFrom(
      this.registrationService.addDocuments(document, document.name, document.type, parentOrganisationId),
    );

    return documentId.id;
  }
}

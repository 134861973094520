import { Inject, Injectable, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { NgxPermissionsService, NgxRolesObject, NgxRolesService } from 'ngx-permissions';
import { RoleCode, ROLE_PERMISSIONS_DEFAULT } from '@src/app/modules/auth';
import { UserUI } from '@src/models';
import { NavigationItem } from '@src/app/modules/navigation';
import { NAVIGATION_ITEMS_TOKEN } from '@src/app/app.module';
import { VisibilityEnum, CustomNamesService, CustomNamesItem } from '@src/app/modules/custom-name-tabs';
import { OrganisationSettingView } from '@src/api';

import { PreferencesService } from './preferences.service';

@Injectable({
  providedIn: 'root',
})
export class DynamicPermissionsService implements OnDestroy {
  private user?: UserUI;
  private readonly destroyed$$ = new Subject<void>();

  constructor(
    private readonly ngxPermissionsService: NgxPermissionsService,
    private readonly ngxRolesService: NgxRolesService,
    private readonly customNamesService: CustomNamesService,
    private readonly preferencesService: PreferencesService,
    @Inject(NAVIGATION_ITEMS_TOKEN) private navigationItems: NavigationItem[],
  ) {
    this.customNamesService.data$.pipe(takeUntil(this.destroyed$$)).subscribe(customNames => {
      this.setDataByCustomNames(this.user, customNames);
    });

    this.preferencesService.settings$.pipe(takeUntil(this.destroyed$$)).subscribe(settings => {
      this.setDataBySettings(this.user, settings);
    });
  }

  ngOnDestroy(): void {
    this.destroyed$$.next();
    this.destroyed$$.complete();
  }

  setData(user?: UserUI) {
    this.user = user;

    this.setDataByCustomNames(this.user, this.customNamesService.data$.value);
    this.setDataBySettings(this.user, this.preferencesService.settings$.value);
  }

  private async setDataByCustomNames(user?: UserUI, customNames: CustomNamesItem[] = []) {
    this.user = user;
    if (!user && !customNames) {
      return;
    }

    const roles = this.ngxRolesService.getRoles();

    customNames.forEach(customName => {
      const findNavigationItem = this.navigationItems.find(navigationItem => navigationItem.id === customName.codeName);

      // TODO: костыль, удалить, когда поправят бэк и отказаться от customNameItem, как раньше было использовать customName
      const customNameItem = this.getCustomName(customNames, customName);

      if (!!findNavigationItem && !!findNavigationItem.permissions) {
        switch (customNameItem.visibilityOption) {
          case VisibilityEnum.INVISIBLE:
            this.removePermissions(findNavigationItem.permissions);
            break;

          case VisibilityEnum.ADMINISTRATORS:
            if (RoleCode.AdminUO in roles) {
              this.addPermissions(findNavigationItem.permissions);
            } else {
              this.removePermissions(findNavigationItem.permissions);
            }
            break;

          case VisibilityEnum.ALL:
            if (
              !!findNavigationItem.permissions.find(permission =>
                this.includePermissionInDefaultPermissions(permission, roles),
              )
            ) {
              this.addPermissions(findNavigationItem.permissions);
            }
            break;

          default:
            this.removePermissions(findNavigationItem.permissions);
            break;
        }
      }
    });
  }

  private setDataBySettings(user?: UserUI, settings?: OrganisationSettingView) {
    this.user = user;
    if (!user && !settings) {
      return;
    }

    if (!!settings?.showAllUsersPhonesAndEmails) {
      this.addPermissions(['organisationEmployeePhoneFieldViewing', 'organisationEmployeeEmailFieldViewing']);
    } else {
      this.removePermissions(['organisationEmployeePhoneFieldViewing', 'organisationEmployeeEmailFieldViewing']);
    }
  }

  private getCustomName(customNames: CustomNamesItem[], customName: CustomNamesItem): CustomNamesItem {
    if (!!customName.title) {
      return customName;
    } else {
      const customNameItemOtherLanguageCode = customNames.filter(
        customNameItem => customNameItem.codeName === customName.codeName && !!customNameItem.title,
      )[0];
      return customNameItemOtherLanguageCode || customName;
    }
  }

  private addPermissions(permission: string | string[]) {
    this.ngxPermissionsService.addPermission(permission);
  }

  private removePermissions(permissions: string | string[]) {
    if (typeof permissions === 'string') {
      this.ngxPermissionsService.removePermission(permissions);
    } else {
      permissions.forEach(permission => this.removePermissions(permission));
    }
  }

  // TODO: проверка разрешения в ролевой модели (дефолтных разрешениях)
  private includePermissionInDefaultPermissions(permission: string, roles: NgxRolesObject) {
    return !!Object.keys(roles).find(role => ROLE_PERMISSIONS_DEFAULT[role as RoleCode].includes(permission));
  }
}

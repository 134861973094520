import { Inject, Injectable } from '@angular/core';
import { DOCUMENT } from '@angular/common';

import { DEEP_LINKS, GOOGLE_SITE_VERIFICATION } from './constants';

@Injectable({
  providedIn: 'root',
})
export class DeepLinksService {
  constructor(@Inject(DOCUMENT) private doc: Document) {}

  init() {
    const hostname = window.location.hostname;
    this.addGoogleSiteVerification(hostname);
    this.addDeepLink(hostname);
  }

  private addGoogleSiteVerification(hostname: string) {
    const content = GOOGLE_SITE_VERIFICATION[hostname];
    if (content) {
      const meta: HTMLMetaElement = this.doc.createElement('meta');
      meta.setAttribute('name', 'google-site-verification');
      meta.setAttribute('content', content);
      this.doc.head.appendChild(meta);
    }
  }

  private addDeepLink(hostname: string) {
    const href = DEEP_LINKS[hostname];
    if (href) {
      const link: HTMLLinkElement = this.doc.createElement('link');
      link.setAttribute('rel', 'alternate');
      link.setAttribute('href', href);
      this.doc.head.appendChild(link);
    }
  }
}

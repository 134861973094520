/**
 * Unions API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Настройки организации.
 */
export interface OrganisationSettingAdd { 
    /**
     * 
     */
    organisationId?: string;
    /**
     * Текст приветственного сообщения новичку.
     */
    newcomerWelcomeText?: string;
    /**
     * Идентификатор приветственного текста.
     */
    newcomerWelcomeTextId?: string;
    /**
     * Заголовок приветственного сообщения новичку.
     */
    newcomerWelcomeTitle?: string;
    /**
     * Идентификатор приветственного заголовка.
     */
    newcomerWelcomeTitleId?: string;
    /**
     * Шаблон письма организации.
     */
    letterTemplate?: string;
    /**
     * Идентификатор шаблона письма.
     */
    letterTemplateId?: string;
    /**
     * Текст приглашения на мероприятие.
     */
    emailEventText?: string;
    /**
     * Текст приглашения на опрос.
     */
    emailPollText?: string;
    /**
     * Ссылка на приложение в App Store.
     */
    appStoreLink?: string;
    /**
     * Ссылка на приложение в Google Play.
     */
    googlePlayLink?: string;
    /**
     * заголовок для страницы авторизации.
     */
    loginScreenTitle?: string;
    /**
     * Описание для страницы авторизации.
     */
    loginScreenDescription?: string;
    /**
     * Отображать в списках сотрудников членов управляющей организации.
     */
    showParentOrgUsersInLists?: boolean;
    /**
     * Отображать в инфоленте только каналы.
     */
    showOnlyChannelsInNewsFeed?: boolean;
    /**
     * Отображать модуль лояльности.
     */
    showLoyaltyModule?: number;
    /**
     * Отображать модуль кооперационных цепочек.
     */
    showCooperationChainsModule?: number;
    /**
     * ссылка на политику конфиденциальности.
     */
    privacyPolicyLink?: string;
    /**
     * опция включения/отключения возможности рассылать дайджест с подборками объявлений ПЛ
     */
    enableLoyaltyNewsletters?: boolean;
    /**
     * Признак: разрешить администраторам организаций создавать мероприятия и опросы
     */
    allowAdminDOCreateEventsAndPolls?: boolean;
    /**
     * Отображать телефоны и емейлы всех пользователей.
     */
    showAllUsersPhonesAndEmails?: boolean;
    /**
     * опция включить/отключить форму регистрацию новых пользователей
     */
    allowUserRegistrationForm?: boolean;
}


<form [formGroup]="form" class="preferences">
  <app-field-wrapper
    [class.tui-skeleton]="loading || saving"
    [text]="'components.preferences.labels.showUsers' | translate"
    [description]="'components.preferences.fields.showParentOrgUsersInLists' | translate"
    [size]="'s'"
  >
    <app-toggle formControlName="showParentOrgUsersInLists"></app-toggle>
  </app-field-wrapper>

  <app-field-wrapper
    [class.tui-skeleton]="loading || saving"
    [text]="'components.preferences.labels.showAllUsersPhonesAndEmails ' | translate"
    [description]="'components.preferences.labels.showAllUsersPhonesAndEmailsDescription' | translate"
    [size]="'s'"
  >
    <app-toggle formControlName="showAllUsersPhonesAndEmails"></app-toggle>
  </app-field-wrapper>

  <app-field-wrapper
    [class.tui-skeleton]="loading || saving"
    [text]="'components.preferences.labels.showOnlyChannels' | translate"
    [description]="'components.preferences.fields.showOnlyChannelsInNewsFeed' | translate"
    [size]="'s'"
  >
    <app-toggle formControlName="showOnlyChannelsInNewsFeed"></app-toggle>
  </app-field-wrapper>

  <!-- TODO: Настройка скрыта из-за того, что автоматическая рассылка дайджеста ещё не сделана #10641 -->
  <!-- <app-field-wrapper
    [class.tui-skeleton]="loading || saving"
    [description]="'components.preferences.labels.enableLoyaltyNewslettersDescription' | translate"
    [text]="'components.preferences.labels.enableLoyaltyNewslettersTitle' | translate"
  >
    <app-toggle formControlName="enableLoyaltyNewsletters"></app-toggle>
  </app-field-wrapper> -->
</form>

<div class="separator"></div>

<div class="preferences">
  <div class="preferences__title text_size-m text_bold">
    {{ 'components.preferences.labels.digestTitle' | translate }}
  </div>

  <app-field-wrapper
    [class.tui-skeleton]="loading || saving"
    [description]="'components.preferences.labels.sendDigestDescription' | translate"
    [text]="'components.preferences.labels.sendDigest' | translate"
    [size]="'s'"
  >
    <button class="preference__button" tuiButton size="xs" (click)="sendDigest()">
      <span>
        {{ 'components.preferences.buttons.sendDigest' | translate }}
      </span>
    </button>
  </app-field-wrapper>

  <app-field-wrapper
    [class.tui-skeleton]="loading || saving"
    [description]="'components.preferences.labels.sendTestDigestDescription' | translate"
    [text]="'components.preferences.labels.sendTestDigest' | translate"
    [size]="'s'"
  >
    <button class="preference__button" tuiButton size="xs" (click)="sendTestDigest()">
      <span>
        {{ 'components.preferences.buttons.sendTestDigest' | translate }}
      </span>
    </button>
  </app-field-wrapper>
</div>

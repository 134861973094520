<contacts-view-old
  [(value)]="value"
  [disabled]="disabled"
  [loading]="loading"
  [allowCreating]="allowCreating"
  [allowEditing]="allowEditing"
  [allowDeleting]="allowDeleting"
  [allowFilesDeleting]="allowFilesDeleting"
  [isModeration]="isModeration"
></contacts-view-old>

import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { ResizableBaseComponent } from '@src/app/components';
import { isFileContact, isNotOtherAndNotFileContact, isOtherContact } from '@src/utils';

@Component({
  selector: 'contacts-view-island',
  templateUrl: './contacts-view-island.component.html',
  styleUrls: ['./contacts-view-island.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContactsViewIslandComponent extends ResizableBaseComponent {
  allowCreating?: boolean | null = false;
  allowEditing?: boolean | null = false;
  allowDeleting?: boolean | null = false;
  loading: boolean = false;

  // TODO: перенести эти утилиты во внутрь модуля в utils.ts
  isFileContactLocal = isFileContact;
  isOtherContactLocal = isOtherContact;
  isNotOtherAndNotFileContactLocal = isNotOtherAndNotFileContact;

  removeContact(index: number): void {
    // this.contacts.removeAt(index);
  }

  addContact(): void {
    // this.contacts.push(this.createContact());
  }
}

import { Injectable, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, from, lastValueFrom, Observable, of, Subject, throwError } from 'rxjs';
import { catchError, filter, map, mergeMap, switchMap, takeUntil, toArray } from 'rxjs/operators';
import { TelegramMessengerService } from '@src/app/modules/telegram';
import { EnvService } from '@src/app/modules/env';
import { logger, normalizeUserDataForUI, sortingUsers } from '@src/utils';
import { AuthUserService, SessionService } from '@src/app/modules/auth';
import {
  AddUser,
  Id,
  UserService as ApiUserService,
  UserProfileWithJobTitlesFull,
  UsersSearchParameters,
  ContactsForUserService,
  ContactForUserView,
  ContactForUserWithUserId,
  LettersService,
} from '@src/api';
import { ChatMemberModel, UserResponseUI, UserUI } from '@src/models';
import { TranslateService } from '@ngx-translate/core';

import { AlertService, BaseSearchService, SettingService } from '../services';
import { APP_CONFIG } from '../config';

// TODO: удалить везде когда поправим back (мы получаем только текст ошибки)
export const ERROR_TEXT_FOR_DELETE_USER =
  'После обновления у пользователя не остается организаций. Должна быть хотя бы одна.';

@Injectable({
  providedIn: 'root',
})
export class UserService implements OnDestroy, BaseSearchService<UsersSearchParameters, UserResponseUI> {
  authUser?: UserUI;
  authUser$ = new BehaviorSubject<UserUI | undefined>(undefined);
  user$ = new BehaviorSubject<UserUI | null>({});
  listOfProfiles$ = new BehaviorSubject<UserUI[]>([{}]);
  membersOfEvent$ = new BehaviorSubject<UserUI[]>([]);
  membersOfPoll$ = new BehaviorSubject<UserUI[] | null>(null);
  membersOfCommittee$ = new BehaviorSubject<UserUI[] | null>(null);
  membersOfOrganisation$ = new BehaviorSubject<UserUI[] | null>(null);
  unregistredUsers$ = new BehaviorSubject<UserUI[] | null>(null);
  usersTelegramIds$ = new BehaviorSubject<number[]>([]);
  sendGreetings$ = new BehaviorSubject<string[] | null>(null);
  loadUsers$ = new BehaviorSubject<number[] | null>(null);
  getUserById$ = new BehaviorSubject<string | number | null>(null);

  filter$: BehaviorSubject<UsersSearchParameters | null> = new BehaviorSubject<UsersSearchParameters | null>(null);
  pagination$: BehaviorSubject<number> = new BehaviorSubject<number>(0);

  private destroyed$$: Subject<void> = new Subject<void>();

  constructor(
    private authUserService: AuthUserService,
    private settingService: SettingService,
    private userService: ApiUserService,
    private lettersService: LettersService,
    private contactsForUserService: ContactsForUserService,
    private messengerService: TelegramMessengerService,
    private router: Router,
    private env: EnvService,
    private readonly alertService: AlertService,
    private readonly sessionService: SessionService,
    private readonly translateService: TranslateService,
  ) {
    logger('UserService constructor');

    this.authUser$ = this.authUserService.authUser$;
    this.authUser$.pipe(takeUntil(this.destroyed$$)).subscribe(user => {
      this.authUser = user;

      // TODO: Текущая логика автоподписок не будет работать, если пользователь пошел в телегу внутри приложения.
      // Параметр isFirstSignIn не подходит для автоподписок - нужно менять логику
      // Параметр isFirstSignIn нужен только для отображения плашек при первом входе
      if (user?.isFirstSignIn && user?.parentOrganisationId) {
        this.settingService.initAutoSubscriptions(user);
        // TODO: это временное решение бага #9186
        this.sessionService.changeToken(user.parentOrganisationId).subscribe();
      }
    });

    this.getUserObservable().pipe(takeUntil(this.destroyed$$)).subscribe();
  }

  ngOnDestroy(): void {
    this.destroyed$$.next();
    this.destroyed$$.complete();
  }

  async getAuthorizedUser(): Promise<UserUI | undefined> {
    return this.authUser;
  }

  loadUsers(membersTelegramIds: number[] | null): void {
    this.loadUsers$.next(membersTelegramIds);
  }

  getUsersObservable(): Observable<{ users: UserUI[] | null; telegramUsers: ChatMemberModel[] | null } | null> {
    let membersTelegramIdsSaved: number[] | null = null;
    let usersSaved: UserUI[] | null = null;
    return this.loadUsers$
      .pipe(filter(membersTelegramIds => membersTelegramIds !== null))
      .pipe(
        switchMap(membersTelegramIds => {
          membersTelegramIdsSaved = membersTelegramIds;
          if (!!membersTelegramIds) {
            return this.getUsersData(membersTelegramIds);
          } else {
            return of(null);
          }
        }),
        switchMap(users => {
          if (!!users && !!membersTelegramIdsSaved) {
            usersSaved = users;
            return from(membersTelegramIdsSaved).pipe(
              mergeMap(membersTelegramId => {
                return this.messengerService.api.getUserObservable(membersTelegramId);
              }),
              toArray(),
            );
          } else {
            return of(null);
          }
        }),
        map(telegramUsers => {
          return {
            users: usersSaved,
            telegramUsers: telegramUsers,
          };
        }),
      )
      .pipe(takeUntil(this.destroyed$$));
  }

  getUsersData(ids: (number | string)[]): Observable<UserUI[]> {
    if (ids.length) {
      const id = ids[0];
      if (typeof id === 'number') {
        return this.userService.getListOfProfiles(ids as number[]).pipe(
          catchError(() => {
            // TODO: добавить локализацию
            this.alertService.error('Ошибка загрузки списка пользователей');
            return of(null);
          }),
          map(users => (users || []).map(user => normalizeUserDataForUI(user))),
        );
      } else if (typeof id === 'string') {
        return this.userService.getUserProfilesByIds(ids as string[]).pipe(
          catchError(() => {
            // TODO: добавить локализацию
            this.alertService.error('Ошибка загрузки списка пользователей');
            return of(null);
          }),
          map(users => (users || []).map(user => normalizeUserDataForUI(user))),
        );
      }
    }

    return of([]);
  }

  getUsersTelegramIds(ids: number[]): void {
    this.userService
      .getListOfProfiles(ids)
      .pipe(
        catchError(err => {
          // TODO show error notification
          return throwError(err);
        }),
        map(users => users.map(user => user.telegramId!)),
        takeUntil(this.destroyed$$),
      )
      .subscribe(telegramIds => this.usersTelegramIds$.next(telegramIds));
  }

  getUser(id: number | string | null, callback?: (value: boolean) => void): Observable<UserUI | null> {
    if (typeof id === 'number') {
      return this.getUsersByTelegramId(id, callback);
    } else if (typeof id === 'string') {
      return this.getUserById(id, callback);
    } else {
      return of(null);
    }
  }

  loadUser(userId: string | number): void {
    this.getUserById$.next(userId);
  }

  getUserObservable(): Observable<UserUI | null> {
    return this.getUserById$.pipe(
      filter(userId => !!userId),
      switchMap(userId => {
        return this.getUser(userId).pipe(
          catchError(() => {
            // TODO: добавить локализацию
            this.alertService.error('Ошибка загрузки пользователя');
            return of(null);
          }),
          map(userUI => {
            return userUI;
          }),
        );
      }),
      map(result => {
        this.user$.next(result);
        return result;
      }),
      takeUntil(this.destroyed$$),
    );
  }

  getUserData(id: string): Observable<UserUI> {
    return this.userService.getUserProfilesByIds([id]).pipe(
      catchError(err => {
        // TODO show error notification
        return throwError(err);
      }),
      map(user => normalizeUserDataForUI(user[0])),
      takeUntil(this.destroyed$$),
    );
  }

  getListOfProfiles(ids: (number | string)[]): void {
    if (ids.length) {
      const id = ids[0];
      if (typeof id === 'number') {
        this.getUsersByTelegramIds(ids as number[]);
      } else if (typeof id === 'string') {
        this.getUsersByIds(ids as string[]);
      }
    } else {
      this.resetListOfProfiles();
    }
  }

  getUnregistredUsers() {
    this.userService
      .newcomersList()
      .pipe(
        catchError(err => {
          this.showError(this.translateService.instant('components.newUsers.alerts.errors.newcomersList'), err);
          return of([]);
        }),
        map(users => sortingUsers(users.map(user => normalizeUserDataForUI(user)))),
        takeUntil(this.destroyed$$),
      )
      .subscribe(users => this.unregistredUsers$.next(users));
  }

  startSendGreetings(userIds: string[] | null) {
    this.sendGreetings$.next(userIds);
  }

  sendGreetings(): Observable<boolean | null> {
    return this.sendGreetings$.pipe(
      filter(request => !!request),
      switchMap(request => {
        return this.lettersService.apiLettersSendGreetingPost({ userIds: request || [] }).pipe(
          catchError(err => {
            this.sendError(err.error || '');
            return of(null);
          }),
          map(response => {
            response?.text && this.alertService.success(response.text);
            return true;
          }),
        );
      }),
      takeUntil(this.destroyed$$),
    );
  }

  getUnregistredUsersForOrganisation(organisationId: string) {
    this.userService
      .getUnregistredUsersForOrganisationWithId(organisationId)
      .pipe(
        catchError(err => {
          // TODO show error notification
          return throwError(err);
        }),
        map(users => sortingUsers(users.map(user => normalizeUserDataForUI(user)))),
        takeUntil(this.destroyed$$),
      )
      .subscribe(users => this.unregistredUsers$.next(users));
  }

  async getUsernameByTelegramId(telegramId: number) {
    return await lastValueFrom(
      this.userService.usernameByTelegramId(telegramId).pipe(
        catchError(err => {
          // TODO show error notification
          return throwError(err);
        }),
        takeUntil(this.destroyed$$),
      ),
    );
  }

  async getUserMutualChats(telegramId: number): Promise<number[]> {
    return await lastValueFrom(
      this.userService.userMutalChats(telegramId).pipe(
        catchError(err => {
          // TODO show error notification
          return throwError(err);
        }),
        takeUntil(this.destroyed$$),
      ),
    );
  }

  getActiveUsers(associationId?: string, organisationId?: string, sendToBot: boolean = false) {
    return this.userService.getActiveUsers(associationId, organisationId, sendToBot);
  }

  async getUserFullName(telegramId: number) {
    const profiles = await lastValueFrom(
      this.userService.getListOfProfiles([telegramId]).pipe(
        catchError(err => {
          // TODO show error notification
          return throwError(err);
        }),
        map(profiles => sortingUsers(profiles.map(profile => normalizeUserDataForUI(profile)))),
        takeUntil(this.destroyed$$),
      ),
    );

    if (profiles?.length) {
      return profiles[0].fullName!;
    }

    return await this.messengerService.getUserFullName(telegramId);
  }

  getUserContacts(id: string) {
    return this.contactsForUserService.contactsForUserList(id).pipe(
      catchError(err => {
        // TODO: добавить локализацию
        this.alertService.error('Ошибка получения дополнительных полей');
        return throwError(err);
      }),
      map(contacts => this.sortingUserContacts(contacts)),
      takeUntil(this.destroyed$$),
    );
  }

  async getUserContactsData(id: string): Promise<ContactForUserView[]> {
    return await lastValueFrom(this.getUserContacts(id));
  }

  async replaceUserContacts(data: ContactForUserWithUserId) {
    return await lastValueFrom(
      this.contactsForUserService.replaceUserContacts(data).pipe(
        catchError(err => {
          // TODO: добавить локализацию
          this.alertService.error('Ошибка при добавлении дополнительных полей');
          return throwError(err);
        }),
        takeUntil(this.destroyed$$),
      ),
    );
  }

  getUsersByOrganisationId(organisationId: string, callback?: (value: boolean) => void): void {
    callback?.(true);

    this.userService
      .getUsersByOrganisationId(organisationId)
      .pipe(
        catchError(err => {
          // TODO show error notification
          callback?.(false);
          return throwError(err);
        }),
        map(profiles => sortingUsers(profiles.map(profile => normalizeUserDataForUI(profile)))),
        takeUntil(this.destroyed$$),
      )
      .subscribe(profiles => {
        this.membersOfOrganisation$.next(profiles);
        callback?.(false);
      });
  }

  getUsersByCommitteeId(committeeId: string, callback?: (value: boolean) => void): void {
    callback?.(true);

    this.userService
      .usersByCommitteeId(committeeId)
      .pipe(
        catchError(err => {
          // TODO show error notification
          callback?.(false);
          return throwError(err);
        }),
        map(profiles => sortingUsers(profiles.map(profile => normalizeUserDataForUI(profile)))),
        takeUntil(this.destroyed$$),
      )
      .subscribe(profiles => {
        this.membersOfCommittee$.next(profiles);
        callback?.(false);
      });
  }

  getUsersByEventId(eventId: string): void {
    this.userService
      .usersByEventId(eventId)
      .pipe(
        catchError(err => {
          // TODO show error notification
          return throwError(err);
        }),
        map(profiles => sortingUsers(profiles.map(profile => normalizeUserDataForUI(profile)))),
        takeUntil(this.destroyed$$),
      )
      .subscribe(profiles => this.membersOfEvent$.next(profiles));
  }

  getUsersValueByEventId(eventId: string): Observable<UserUI[]> {
    return this.userService.usersByEventId(eventId).pipe(
      catchError(err => {
        // TODO show error notification
        return throwError(err);
      }),
      map(profiles => sortingUsers(profiles.map(profile => normalizeUserDataForUI(profile)))),
    );
  }

  getUsersByPollId(pollId: string): void {
    this.userService
      .usersByPollId(pollId)
      .pipe(
        catchError(err => {
          // TODO show error notification
          return throwError(err);
        }),
        map(profiles => sortingUsers(profiles.map(profile => normalizeUserDataForUI(profile)))),
        takeUntil(this.destroyed$$),
      )
      .subscribe(profiles => this.membersOfPoll$.next(profiles));
  }

  getUserByPhone(phone: string, callback?: (value: boolean) => void) {
    callback?.(true);

    this.userService
      .userProfilesByPhone(phone)
      .pipe(
        catchError(err => {
          // TODO show error notification
          callback?.(false);
          return throwError(err);
        }),
        map(profile => normalizeUserDataForUI(profile as UserProfileWithJobTitlesFull)),
        takeUntil(this.destroyed$$),
      )
      .subscribe(user => {
        this.user$.next(user);
        callback?.(false);
      });
  }

  createUser(data: AddUser): Observable<Id> {
    return this.userService.addUser(data).pipe(
      catchError(err => {
        this.sendError(err);
        return throwError(err);
      }),
    );
  }

  // TODO: возможно лучше добавить метод загрузки фото к организации
  editUser(data: UserUI): Observable<Id> {
    return this.userService.editUser(data).pipe(
      catchError(err => {
        if (err.status !== 492 && err !== ERROR_TEXT_FOR_DELETE_USER) {
          this.sendError(err);
        }
        return throwError(err);
      }),
    );
  }

  deleteUser(userId: string, successorId?: string): Observable<void> {
    return this.userService.deleteUser(userId, successorId).pipe(
      catchError(err => {
        // TODO handle error
        return throwError(err);
      }),
    );
  }

  search(filter: UsersSearchParameters, pagination: number): Observable<UserResponseUI> {
    const top = APP_CONFIG.loadingLimit.users;
    const start = pagination * APP_CONFIG.loadingLimit.users;

    return this.userService.searchUsers(top, start, false, filter).pipe(
      map(response => {
        response.result = response.result?.map(item => normalizeUserDataForUI(item));

        return response;
      }),
      catchError(err => {
        // TODO handle error
        return throwError(() => err);
      }),
    );
  }

  searchUsers(filter: UsersSearchParameters): Observable<UserUI[]> {
    return this.userService.searchUsers(3000, 0, false, filter).pipe(
      map(response => (response.result ? response.result.map(user => normalizeUserDataForUI(user)) : [])),
      catchError(err => {
        // TODO handle error
        return throwError(() => err);
      }),
    );
  }

  getAllContactsForUserDescriptionsForOrganisations(): Observable<Array<string>> {
    return this.contactsForUserService.allContactsForUserDescriptionsForOrganisations().pipe(
      catchError(errorMessage => {
        this.showError(
          this.translateService.instant(
            'components.users.alerts.errors.allContactsForUserDescriptionsForOrganisations',
          ),
          errorMessage,
        );
        return of([]);
      }),
    );
  }

  getUnionsChatsIds(tgChatsIds: number[]) {
    return this.userService.filter(tgChatsIds).pipe(
      catchError(err => {
        // TODO handle error
        return throwError(err);
      }),
    );
  }

  resetUser(): void {
    this.user$.next(null);
  }

  resetListOfProfiles(): void {
    this.listOfProfiles$.next([{}]);
  }

  resetUnregistredUsers(): void {
    this.unregistredUsers$.next(null);
  }

  resetMembersOfEvent(): void {
    this.membersOfEvent$.next([]);
  }

  resetMembersOfPoll(): void {
    this.membersOfPoll$.next(null);
  }

  resetMembersOfCommittee(): void {
    this.membersOfCommittee$.next(null);
  }

  resetMembersOfOrganisation(): void {
    this.membersOfOrganisation$.next(null);
  }

  resetAll(): void {
    this.resetUser();
    this.resetListOfProfiles();
    this.resetUnregistredUsers();
    this.resetMembersOfEvent();
    this.resetMembersOfPoll();
    this.resetMembersOfCommittee();
    this.resetMembersOfOrganisation();
  }

  getSearchUsers(users: UserUI[], searchQuery: string): UserUI[] {
    const query = searchQuery.toLowerCase();
    const filteredUsers = users.filter(user => {
      const firstName = user.firstName?.toLowerCase();
      const middleName = user.middleName?.toLowerCase();
      const lastName = user.lastName?.toLowerCase();
      const fml = [firstName, middleName, lastName].join(' ');
      const flm = [firstName, lastName, middleName].join(' ');
      const mfl = [middleName, firstName, lastName].join(' ');
      const mlf = [middleName, lastName, firstName].join(' ');
      const lfm = [lastName, firstName, middleName].join(' ');
      const lmf = [lastName, middleName, firstName].join(' ');

      return (
        fml.includes(query) ||
        flm.includes(query) ||
        mfl.includes(query) ||
        mlf.includes(query) ||
        lfm.includes(query) ||
        lmf.includes(query)
      );
    });

    return filteredUsers;
  }

  async communicationWithCurator(): Promise<void> {
    const curatorId = this.authUser?.curatorId;

    if (!curatorId) return;

    const [curator] = await lastValueFrom(this.getUsersData([curatorId]));

    this.openChatWithUser(curator);
  }

  /**
   * Чтение профиля пользователя по идентификатору Телеграм
   * @param telegramId идентификатор записи
   */
  getUserByTelegramId(telegramId: number): Observable<UserProfileWithJobTitlesFull> {
    return this.userService.getListOfProfiles([telegramId]).pipe(
      catchError(err => throwError(err)),
      map(users => users[0]),
    );
  }

  openChatWithUser(user: UserUI) {
    if (this.env.isBot && user.phone) {
      this.env.openLink(user.phone, true);
    } else if (user.telegramId) {
      this.router.navigate(['/chats', user.telegramId]);
    }
  }

  private getUserById(id: string, callback?: (value: boolean) => void): Observable<UserUI> {
    callback?.(true);

    return this.userService
      .getUserProfilesByIds([id])
      .pipe(
        catchError(err => {
          // TODO show error notification
          callback?.(false);
          return throwError(err);
        }),
        map(users => sortingUsers(users.map(user => normalizeUserDataForUI(user)))),
        takeUntil(this.destroyed$$),
      )
      .pipe(
        map(users => {
          this.user$.next(users[0]);
          callback?.(false);
          return users[0];
        }),
      );
  }

  private getUsersByTelegramId(id: number, callback?: (value: boolean) => void): Observable<UserUI> {
    callback?.(true);

    return this.userService
      .getListOfProfiles([id])
      .pipe(
        catchError(err => {
          // TODO show error notification
          callback?.(false);
          return throwError(err);
        }),
        map(users => sortingUsers(users.map(user => normalizeUserDataForUI(user)))),
        takeUntil(this.destroyed$$),
      )
      .pipe(
        map(users => {
          this.user$.next(users[0]);
          callback?.(false);
          return users[0];
        }),
      );
  }

  private getUsersByIds(ids: string[]): void {
    this.userService
      .getUserProfilesByIds(ids)
      .pipe(
        catchError(err => {
          // TODO show error notification
          return throwError(err);
        }),
        map(profiles => sortingUsers(profiles.map(profile => normalizeUserDataForUI(profile)))),
        takeUntil(this.destroyed$$),
      )
      .subscribe(profiles => this.listOfProfiles$.next(profiles));
  }

  private getUsersByTelegramIds(ids: number[]): void {
    this.userService
      .getListOfProfiles(ids)
      .pipe(
        catchError(err => {
          // TODO show error notification
          return throwError(err);
        }),
        map(profiles => sortingUsers(profiles.map(profile => normalizeUserDataForUI(profile)))),
        takeUntil(this.destroyed$$),
      )
      .subscribe(profiles => this.listOfProfiles$.next(profiles));
  }

  private sendError(message: string): void {
    this.alertService.error(message, {
      label: this.translateService.instant('common.alerts.errors.error2'),
      autoClose: false,
    });
  }

  private sortingUserContacts(contacts: ContactForUserView[]): ContactForUserView[] {
    contacts
      ?.sort((a, b) => a.sortOrder! - b.sortOrder!)
      .map((contact, index) => {
        contact.sortOrder = (index + 1) * 10;
        return contact;
      });

    return contacts;
  }

  private showError(title: string, err: unknown) {
    const message = err instanceof Error ? err.message : err;
    this.alertService.error(`${title}\n${message}`);
  }
}

import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Inject,
  Injector,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import {
  AbstractControl,
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { BreakpointObserver } from '@angular/cdk/layout';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { map, takeUntil } from 'rxjs/operators';
import { forkJoin, of } from 'rxjs';
import { TuiContextWithImplicit, TuiDay, tuiPure, TuiStringHandler, TuiTime } from '@taiga-ui/cdk';
import { tuiCreateTimePeriods } from '@taiga-ui/kit';
import { TuiDialogService } from '@taiga-ui/core';
import { PolymorpheusComponent } from '@tinkoff/ng-polymorpheus';
import { TranslateService } from '@ngx-translate/core';
import { APP_CONFIG } from '@src/core';
import {
  AlertService,
  BreakpointObserverHelperService,
  DocumentService,
  PhotoService,
  PollService,
} from '@src/core/services';
import { AnswerType, Multimedia, PollUI, QuestionFormType, TuiFileLikeUI, UserUI, ViewMode } from '@src/models';
import { PollFullView, convertDateToApiFormat, AnswerOption, PollWithUserDecisionView } from '@src/api';
import { ResizableBaseComponent } from '@src/app/components/resizable-base-component';
import { DialogConfirmComponent } from '@src/app/shared/dialogs';
import { convertBlobToFile, convertFileToMultimedia } from '@src/utils';

import { ANSWER_TYPES } from '../constants';

import { FormData, FormDataControls } from './types';
import { NONE_TEMPLATE } from './constants';

@Component({
  selector: 'app-poll-info-edit',
  templateUrl: './poll-info-edit.component.html',
  styleUrls: ['./poll-info-edit.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PollInfoEditComponent extends ResizableBaseComponent implements OnChanges {
  @Input() mode: ViewMode = 'create';
  @Input() data?: PollFullView | null;
  @Input() oldDocuments?: TuiFileLikeUI[] | null;
  @Input() membersList?: UserUI[] | null;
  @Input() externalLoading?: boolean = true;
  @Output() saved: EventEmitter<PollUI | null> = new EventEmitter();
  @Output() canceled: EventEmitter<void> = new EventEmitter();

  infoForm!: UntypedFormGroup;
  innerLoading: boolean = true;
  newPhoto?: Multimedia;
  readonly maxDocFileSize = APP_CONFIG.fileSizeMax.doc;
  readonly timeItems = tuiCreateTimePeriods(0, 24, [0, 15, 30, 45]);
  readonly answerTypes: AnswerType[] = ANSWER_TYPES;

  readonly templates$ = this.pollService.getTemplatesList().pipe(
    map(templates => {
      templates.unshift(NONE_TEMPLATE);
      return templates;
    }),
  );

  private lastStateTemplateId?: string = NONE_TEMPLATE.id;
  private preventClearQuestionAfterChangeType = false;

  private readonly confirmTemplateChangeDialog = this.dialogService.open<boolean>(
    new PolymorpheusComponent(DialogConfirmComponent, this.injector),
    {
      label: this.translateService.instant('components.pollInfoEdit.dialogs.templateChange'),
      size: 's',
      closeable: false,
    },
  );

  constructor(
    readonly cdr: ChangeDetectorRef,
    readonly breakpointObserver: BreakpointObserver,
    readonly breakpointObserverHelperService: BreakpointObserverHelperService,
    private formBuilder: UntypedFormBuilder,
    private alertService: AlertService,
    private readonly translateService: TranslateService,
    private readonly pollService: PollService,
    private readonly documentService: DocumentService,
    private readonly photoService: PhotoService,
    @Inject(TuiDialogService) private readonly dialogService: TuiDialogService,
    @Inject(Injector) private readonly injector: Injector,
  ) {
    super(cdr, breakpointObserver, breakpointObserverHelperService);

    this.initForm();
  }

  get loading() {
    return this.externalLoading || this.innerLoading;
  }

  get dateStart(): TuiDay {
    return this.infoForm.get('dateStart')?.value ?? new TuiDay(2000, 0, 1);
  }

  get dateEnd(): TuiDay {
    return this.infoForm.get('dateEnd')?.value ?? new TuiDay(3000, 0, 1);
  }

  // TODO: refactoring - https://angular24.ru/guide/dynamic-form#модель-вопроса
  get questions(): UntypedFormArray {
    return this.infoForm.get('questions') as UntypedFormArray;
  }

  get isTemplateControl() {
    return this.infoForm.get('isTemplate');
  }

  get isTemplateValue(): boolean {
    return this.infoForm.get('isTemplate')?.value ?? false;
  }

  get templateIdControl() {
    return this.infoForm.get('templateId');
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.data) {
      this.setFormData(this.data);
    }

    if (changes.mode) {
      if (this.mode === 'create') {
        this.innerLoading = false;
      }
    }

    if (changes.oldDocuments) {
      this.infoForm.get('documents')?.patchValue(this.oldDocuments);
    }

    if (changes.membersList && this.membersList) {
      this.infoForm.patchValue({
        members: this.membersList,
      });
    }

    this.cdr.markForCheck();
  }

  addQuestion(): void {
    this.questions.push(this.createQuestion());
  }

  removeQuestion(index: number): void {
    this.questions.removeAt(index);
  }

  moveQuestion(currentIndex: number, index: number) {
    moveItemInArray(this.questions.controls, currentIndex, currentIndex + index);
  }

  getAnswerOptionsByIndex(questionIndex: number): UntypedFormArray {
    const question = this.getQuestionByIndex(questionIndex);
    return this.getAnswerOptions(question);
  }

  addAnswerOptionByIndex(questionIndex: number, answer?: AnswerOption): void {
    const question = this.getQuestionByIndex(questionIndex);
    this.addAnswerOption(question, answer);
  }

  removeAnswerOption(questionIndex: number, index: number): void {
    const question = this.getQuestionByIndex(questionIndex);
    this.getAnswerOptions(question).removeAt(index);
  }

  onDropAnswers(event: CdkDragDrop<string[]>, questionIndex: number) {
    moveItemInArray(this.getAnswerOptionsByIndex(questionIndex).controls, event.previousIndex, event.currentIndex);
  }

  getOtherVisible(questionIndex: number): boolean {
    const answerOptions = this.getAnswerOptionsByIndex(questionIndex);
    for (let i = 0; i < answerOptions.length; i++) {
      if (answerOptions.at(i)?.value?.isAlternative) {
        return false;
      }
    }
    return true;
  }

  onClickSaveButton(): void {
    this.infoForm.markAllAsTouched();

    if (this.infoForm.invalid) {
      this.alertService.error(this.translateService.instant('common.alerts.errors.fillRequired'));
      return;
    }

    const pollData = this.mapFormDataToPollUI(this.infoForm.getRawValue());
    if (!pollData) return;

    // Не отправляем вопросы, если при редактировании параметр canEditPollQuestions === false.
    // Редактируем только данные опроса
    if (this.mode === 'edit' && !this.data?.canEditPollQuestions) {
      pollData.questions = undefined;
    }

    this.newPhoto = undefined;
    this.saved.emit(pollData);
  }

  onClickCancelButton(): void {
    this.newPhoto = undefined;
    this.canceled.emit();
  }

  onPhotoChange(newPhoto?: Multimedia): void {
    this.newPhoto = newPhoto;
    this.cdr.markForCheck();
  }

  onQuestionPhotoChange(questionIndex: number, newPhoto?: Multimedia) {
    this.questions.at(questionIndex).patchValue({ photo: newPhoto?.file });
  }

  onChangeSelectedCommitteeIds([committeeId]: string[]) {
    this.infoForm.patchValue({ committeeId: committeeId });
  }

  removeFile(formControl: AbstractControl | null, file: any): void {
    if (!!formControl) {
      formControl.setValue(formControl.value?.filter((current: File) => current !== file) ?? []);
    }
  }

  @tuiPure
  templatesStringify(items: readonly PollWithUserDecisionView[]): TuiStringHandler<TuiContextWithImplicit<string>> {
    const map = new Map(items.map(({ id, titleText }) => [id, titleText]));
    return ({ $implicit }: TuiContextWithImplicit<string>) =>
      $implicit !== NONE_TEMPLATE.id ? map.get($implicit.toString()) || '' : NONE_TEMPLATE.titleText || '';
  }

  private initForm(): void {
    this.infoForm = new UntypedFormGroup(<FormDataControls>{
      titleText: new UntypedFormControl('', Validators.required),
      descriptionText: new UntypedFormControl(''),
      pollDuration: new UntypedFormControl(),
      dateStart: new UntypedFormControl(null, Validators.required),
      timeStart: new UntypedFormControl(null, Validators.required),
      dateEnd: new UntypedFormControl(null, Validators.required),
      timeEnd: new UntypedFormControl(null, Validators.required),
      documents: new UntypedFormControl(),
      members: new UntypedFormControl(null),
      questions: this.formBuilder.array([]),
      committeeId: new UntypedFormControl(),

      completionText: new UntypedFormControl('', Validators.maxLength(2000)),
      displayStepByStep: new UntypedFormControl(false),
      timeLimit: new UntypedFormControl(0),
      registerTime: new UntypedFormControl(false),
      isTemplate: new UntypedFormControl(false),
      templateId: new UntypedFormControl(NONE_TEMPLATE.id),
    });

    if (this.mode === 'create') {
      // Логика при указании опроса как шаблон
      this.isTemplateControl?.valueChanges.pipe(takeUntil(this.destroyed$$)).subscribe(value => {
        this.changeValueControl(value, this.infoForm.get('dateStart'));
        this.changeValueControl(value, this.infoForm.get('timeStart'));
        this.changeValueControl(value, this.infoForm.get('dateEnd'));
        this.changeValueControl(value, this.infoForm.get('timeEnd'));
      });

      // Логика при смене шаблона
      this.templateIdControl?.valueChanges.pipe(takeUntil(this.destroyed$$)).subscribe(templateId => {
        if (templateId === NONE_TEMPLATE.id) {
          this.lastStateTemplateId = templateId;
          return;
        }

        if (this.lastStateTemplateId !== templateId) {
          this.confirmTemplateChangeDialog.subscribe(res => {
            if (res) {
              this.lastStateTemplateId = templateId;
              this.innerLoading = true;
              this.getCloneData(templateId).subscribe(cloneData => this.setFormData(cloneData));
            } else {
              this.templateIdControl?.setValue(this.lastStateTemplateId);
            }
          });
        }
      });
    }
  }

  private mapPollUIToFormData(poll: PollFullView) {
    const {
      titleText,
      descriptionText,
      pollDuration,
      dateStart,
      dateEnd,
      questions,
      committeeId,
      completionText,
      displayStepByStep,
      timeLimit,
      registerTime,
      isTemplate,
      templateId,
    } = poll;

    const startNormalize = dateStart ? new Date(dateStart) : undefined; // TODO: delete after fix nswag DateTime format
    const dateStartNormalize = startNormalize
      ? new TuiDay(startNormalize.getFullYear(), startNormalize.getMonth(), startNormalize.getDate())
      : undefined;
    const timeStartNormalize = startNormalize
      ? new TuiTime(startNormalize.getHours(), startNormalize.getMinutes())
      : undefined;

    const endNormalize = dateEnd ? new Date(dateEnd) : undefined; // TODO: delete after fix nswag DateTime format
    const dateEndNormalize = endNormalize
      ? new TuiDay(endNormalize.getFullYear(), endNormalize.getMonth(), endNormalize.getDate())
      : undefined;
    const timeEndNormalize = endNormalize ? new TuiTime(endNormalize.getHours(), endNormalize.getMinutes()) : undefined;

    const questionsFormatted = (questions as QuestionFormType[])?.map((question, index) => {
      this.addQuestion();
      question.answers?.forEach(answer => this.addAnswerOptionByIndex(index, answer));
      question.answerType = this.answerTypes.find(answerType => answerType.id === question.answerTypeId);
      return question;
    });

    return <FormData>{
      titleText,
      descriptionText,
      pollDuration,
      dateStart: dateStartNormalize,
      timeStart: timeStartNormalize,
      dateEnd: dateEndNormalize,
      timeEnd: timeEndNormalize,
      questions: questionsFormatted,
      members: this.membersList,
      documents: [],
      committeeId,
      completionText: completionText || '',
      displayStepByStep: displayStepByStep || false,
      timeLimit: timeLimit || 0,
      registerTime: registerTime || false,
      isTemplate: isTemplate || false,
      templateId: templateId || NONE_TEMPLATE.id,
    };
  }

  private mapFormDataToPollUI(formData: FormData) {
    const {
      titleText,
      descriptionText,
      pollDuration,
      members,
      documents,
      committeeId,
      completionText,
      displayStepByStep,
      timeLimit,
      registerTime,
      isTemplate,
      templateId,
    } = formData;

    const dateStart = !isTemplate
      ? new Date(
          formData.dateStart.year,
          formData.dateStart.month,
          formData.dateStart.day,
          formData.timeStart.hours,
          formData.timeStart.minutes,
        )
      : undefined;

    const dateEnd = !isTemplate
      ? new Date(
          formData.dateEnd.year,
          formData.dateEnd.month,
          formData.dateEnd.day,
          formData.timeEnd.hours,
          formData.timeEnd.minutes,
        )
      : undefined;

    const questions = formData.questions?.map((question, index) => {
      const answers = question.answers?.map((answer, index) => {
        answer.sortOrder = index;
        return answer;
      });

      return {
        answerTypeId: question?.answerType?.id,
        text: question.text,
        answers,
        photo: question.photo,
        photoId: question.photoId,
        sortOrder: index,
      } as QuestionFormType;
    });

    if (
      questions.find(
        question => (question.answerTypeId === 2 || question.answerTypeId === 3) && !question.answers?.length, // 2 - radio, 3 - check
      )
    ) {
      this.alertService.error(this.translateService.instant('components.pollInfo.alerts.errors.noAnswers'));
      return;
    }

    const deleteDocuments = this.oldDocuments?.filter(oldDocument => !documents?.includes(oldDocument));
    const newDocuments = documents?.filter(doc => !doc.id);

    const sendTo = members?.map(member => member.id);

    return <PollUI>{
      titleText,
      descriptionText,
      dateStart: convertDateToApiFormat(dateStart),
      dateEnd: convertDateToApiFormat(dateEnd),
      pollDuration,
      questions,
      sendTo,
      newDocuments,
      deleteDocuments,
      id: this.data?.id,
      photo: this.newPhoto?.file,
      committeeId,

      completionText,
      displayStepByStep,
      timeLimit,
      registerTime,
      isTemplate,
      templateId: templateId === NONE_TEMPLATE.id ? null : templateId,
    };
  }

  private getQuestionByIndex(questionIndex: number): AbstractControl {
    return this.questions.at(questionIndex);
  }

  private createQuestion(): UntypedFormGroup {
    const question = this.formBuilder.group({
      text: ['', Validators.required],
      answerType: [null, Validators.required],
      answers: this.formBuilder.array([]),
      photo: [null],
      photoId: [null],
    });

    if (!this.preventClearQuestionAfterChangeType) {
      question
        .get('answerType')
        ?.valueChanges.pipe(takeUntil(this.destroyed$$))
        .subscribe(() => {
          this.removeAllAnswerOptions(question);
          this.addAnswerOption(question);
        });
    }

    return question;
  }

  private createAnswerOption(index: number = 0, answer?: AnswerOption): UntypedFormGroup {
    return this.formBuilder.group({
      textValue: [
        answer?.isAlternative
          ? {
              value: answer?.textValue ?? this.translateService.instant('components.pollInfo.labels.other') + '...',
              disabled: true,
            }
          : answer?.textValue ?? this.translateService.instant('components.pollInfo.labels.option') + ' ' + (index + 1),
        Validators.required,
      ],
      isAlternative: answer?.isAlternative ?? false,
      sortOrder: answer?.sortOrder ?? index,
    });
  }

  private getAnswerOptions(question: AbstractControl): UntypedFormArray {
    return question.get('answers') as UntypedFormArray;
  }

  private addAnswerOption(question: AbstractControl, answer?: AnswerOption): void {
    const answerOptions = this.getAnswerOptions(question);
    answerOptions.push(this.createAnswerOption(answerOptions.length, answer));
  }

  private removeAllAnswerOptions(question: AbstractControl): void {
    const answerOptions = this.getAnswerOptions(question);
    for (let i = answerOptions.length - 1; i >= 0; i--) {
      answerOptions.removeAt(i);
    }
  }

  private changeValueControl(value: boolean, control: AbstractControl | null) {
    control?.clearValidators();
    if (value) {
      control?.removeValidators(Validators.required);
      control?.disable({ onlySelf: true, emitEvent: false });
    } else {
      control?.addValidators(Validators.required);
      control?.enable({ onlySelf: true, emitEvent: false });
    }
  }

  private setFormData(data?: PollFullView | null) {
    this.innerLoading = true;
    this.initForm();
    if (data) {
      try {
        this.preventClearQuestionAfterChangeType = true;
        const mappedData = this.mapPollUIToFormData(data);

        this.infoForm.patchValue(mappedData);
      } finally {
        this.preventClearQuestionAfterChangeType = false;
        this.innerLoading = false;
      }
    }
    this.cdr.markForCheck();
  }

  private getCloneData(templateId: string) {
    return this.pollService
      .getPollData(templateId)
      .pipe(
        map(data => {
          // TODO: refactoring сделать параллельную загрузку всех файлов и после этого отдавать data
          if (data.photoId) {
            this.photoService
              .downloadPhotoById(data.photoId)
              .pipe(takeUntil(this.destroyed$$))
              .subscribe(photo => {
                const file = convertBlobToFile(photo, 'photo');
                this.newPhoto = convertFileToMultimedia(file);
              });
          }

          if (data.id) {
            this.documentService
              .getDocumentsData(data.id)
              .pipe(
                map(documentsInfo => {
                  return documentsInfo.map(documentInfo => {
                    if (documentInfo.id) {
                      return this.documentService.downloadDocumentData(documentInfo.id).pipe(
                        map(document => {
                          return convertBlobToFile(document, documentInfo.fileName);
                        }),
                      );
                    } else {
                      return of(null);
                    }
                  });
                }),
              )
              .pipe(takeUntil(this.destroyed$$))
              .subscribe(documents => {
                forkJoin(documents)
                  .pipe(takeUntil(this.destroyed$$))
                  .subscribe(files => {
                    const documents = files.filter(file => !!file);
                    this.infoForm.patchValue({ documents });
                  });
              });
          }

          return data;
        }),
      )
      .pipe(
        map(data => {
          if (!data) {
            return;
          }

          const {
            titleText,
            descriptionText,
            completionText,
            pollDuration,
            timeLimit,
            displayStepByStep,
            registerTime,
            questions,
          } = data;

          const cloneData: PollFullView = {
            templateId,
            isTemplate: false,

            titleText,
            descriptionText,
            completionText,
            pollDuration,
            timeLimit,
            displayStepByStep,
            registerTime,
            questions,
          };

          return cloneData;
        }),
      )
      .pipe(takeUntil(this.destroyed$$));
  }
}
